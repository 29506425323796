import { Component, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-product-number-cell',
  templateUrl: './product-number-cell.component.html',
  styleUrls: ['./product-number-cell.component.scss'],
})
export class ProductNumberCellComponent implements ICellRendererAngularComp {
  params: any;
  selectedValue: string = '';
  options: string[] = [];
  filteredOptions: string[] = [];
  public label?: string | null = '';
  content: string = '';
  public model = '';
  @ViewChild('auto') matAutocomplete?: any;

  agInit(params: any): void {
    this.params = params;

    // Ensure params.value is an object
    if (typeof params.value === 'string') {
      this.content = params.value;
      this.options = [];
      params.value = { select: '', options: [] }; // Ensure it's an object
    } else if (params?.value?.list) {
      this.options = params?.value?.list;
    } else {
      this.options = [];
    }

    this.filteredOptions = [...this.options];

    this.selectedValue = this.validateSelectedValue(
      params?.value?.selectedProductNumber
    );

    // Ensure params.value is an object before modifying
    if (typeof params.value !== 'object' || params.value === null) {
      params.value = { select: '', options: [] };
    }
    this.options = params.value.list || [];

    if (params?.value?.select) {
      this.model = params?.value?.selectedProductNumber;
    } else {
      params.value.select = this.options.length ? this.options[0] : '';
      this.model = params.value.selectedProductNumber;
    }

    this.label = params?.value?.value ?? '';

    setTimeout(() => {
      const find = this.matAutocomplete?.options?._results?.find(
        (dr: any) => dr.value == this.model
      );

      if (find) {
        find._selected = true;
      }
    }, 100);
  }

  refresh(params: any): boolean {
    this.params = params;
    this.options = params?.value?.list ?? [];
    this.selectedValue = this.validateSelectedValue(
      params?.value?.selectedProductNumber
    );
    return true;
  }

  validateSelectedValue(selected: string | undefined): string {
    return selected && this.options.includes(selected)
      ? selected
      : this.options[0] ?? '';
  }

  onSelectChange(selectedValue: string) {
    this.selectedValue = selectedValue;
    if (this.params.onSelectionChange) {
      this.params.onSelectionChange(selectedValue);
    }
  }

  handleKeydown(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === 'a') {
      const inputElement = event.target as HTMLInputElement;
      inputElement.select();
      event.preventDefault();
    }
  }

  keypress(event: KeyboardEvent): void {
    const inputValue = this.model.trim();

    if (event.key === 'Enter' && inputValue !== '') {
      if (!this.options.includes(inputValue)) {
        this.options.push(inputValue); // Add new value to full options list
      }

      if (!this.filteredOptions.includes(inputValue)) {
        this.filteredOptions.push(inputValue); // Ensure new value is in the filtered list
      }

      if (typeof this.params.onEdit === 'function') {
        this.params.onEdit(
          this.params.node.rowIndex,
          this.params.column.colId,
          inputValue
        );
      }
    } else {
      this.filterOptions(inputValue);
    }
  }

  filterOptions(searchText: string): void {
    if (!searchText) {
      this.filteredOptions = [...this.options];
      return;
    }

    this.filteredOptions = this.options.filter((option: string) =>
      option.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  setModel(text: string) {
    this.model = text;

    if (!this.options.includes(text)) {
      this.options.push(text);
    }

    if (typeof this.params.onEdit === 'function') {
      this.params.onEdit(
        this.params.node.rowIndex,
        this.params.column.colId,
        text
      );
    } else {
      console.warn('onEdit function is not defined in params.');
    }
  }
}
