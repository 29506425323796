<div class="pagination">
  <button *ngIf="currentPage > 1" (click)="onPageClick(currentPage - 1)">
    <svg-icon
      src="assets/img/newDesign/conversation/prev.svg"
      [applyClass]="true"
      class="ap-w-[4px] ap-h-[8px]"
    ></svg-icon>
  </button>

  <button
    *ngFor="let page of pagesToShow"
    [class.active]="page === currentPage"
    (click)="onPageClick(page)"
    [disabled]="page === '...'"
  >
    {{ page }}
  </button>

  <button
    *ngIf="currentPage < totalPages"
    (click)="onPageClick(currentPage + 1)"
  >
    <svg-icon
      src="assets/img/newDesign/conversation/next.svg"
      [applyClass]="true"
      class="ap-w-[4px] ap-h-[8px]"
    ></svg-icon>
  </button>
</div>
