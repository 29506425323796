import { ActionType, TriggerType } from '@upbrains/shared';

export function getDisplayNameForTrigger(triggerType: TriggerType) {
  switch (triggerType) {
    case TriggerType.WEBHOOK: {
      return $localize`Webhook Trigger`;
      break;
    }
    case TriggerType.EMPTY: {
      return $localize`Empty Trigger`;
    }
  }
  return $localize`Trigger`;
}

export function getDefaultDisplayNameForAgent(
  agentType: ActionType,
  agentName: string
) {
  switch (agentType) {
    case ActionType.CODE: {
      return $localize`Code`;
    }
    case ActionType.LOOP_ON_ITEMS: {
      return $localize`Loop on Items`;
    }
    case ActionType.AGENT: {
      return agentName;
    }
    case ActionType.BRANCH: {
      return $localize`Binary Choice`;
    }
  }
}

export function isOverflown(element: HTMLElement) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

export function getLeftDaysOfSubscription(
  timestamp: number | null | undefined
): number {
  if (timestamp) {
    const targetDate: Date = new Date(timestamp * 1000);
    const currentDate: Date = new Date();
    const timeDifference: number = targetDate.getTime() - currentDate.getTime();
    let daysLeft: number = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (daysLeft > 0) {
      return daysLeft;
    }
    return 0;
  }
  return 0;
}

export function base64ToFile(
  base64String: string,
  filename: string
): { file: File; fileURL: string } {
  let rawBase64Code = base64String;
  if (base64String.includes('data:application/pdf;base64,')) {
    rawBase64Code = base64String.replace('data:application/pdf;base64,', '');
  } else if (base64String.includes('data:image/png;base64,')) {
    rawBase64Code = base64String.replace('data:image/png;base64,', '');
  } else if (base64String.includes('data:image/jpg;base64,')) {
    rawBase64Code = base64String.replace('data:image/jpg;base64,', '');
  } else if (base64String.includes('data:image/jpeg;base64,')) {
    rawBase64Code = base64String.replace('data:image/jpeg;base64,', '');
  }
  const byteCharacters = atob(rawBase64Code);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const file = new File([byteArray], filename, {
    type: 'application/octet-stream',
  });

  const fileURL = URL.createObjectURL(file);

  return { file, fileURL };
}

export function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file); // This ensures the output includes the MIME type
  });
}
