import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-paginate',
  templateUrl: './paginate.component.html',
  styleUrls: ['./paginate.component.scss'],
})
export class PaginateComponent {
  @Input() totalPages: number = 10; // Total number of pages
  @Input() currentPage: number = 1; // Current page
  @Output() pageChange = new EventEmitter<number>();

  pagesToShow: (number | string)[] = [];

  ngOnChanges() {
    this.calculatePages();
  }

  calculatePages() {
    const maxVisiblePages = 6;

    if (this.totalPages <= maxVisiblePages) {
      this.pagesToShow = Array.from(
        { length: this.totalPages },
        (_, i) => i + 1
      );
      return;
    }

    const pages: (number | string)[] = [];

    if (this.currentPage <= 3) {
      pages.push(1, 2, 3, '...', this.totalPages - 1, this.totalPages);
    } else if (this.currentPage >= this.totalPages - 2) {
      pages.push(
        1,
        2,
        '...',
        this.totalPages - 2,
        this.totalPages - 1,
        this.totalPages
      );
    } else {
      pages.push(
        1,
        '...',
        this.currentPage - 1,
        this.currentPage,
        this.currentPage + 1,
        '...',
        this.totalPages
      );
    }

    this.pagesToShow = pages;
  }

  onPageClick(page: number | string) {
    if (page === '...') return;

    this.currentPage = page as number;
    this.calculatePages();
    this.pageChange.emit(this.currentPage);
  }
}
