import { Static, Type } from '@sinclair/typebox'

export const FileDataType = Type.Object({
    file_name: Type.String(),
})

export const KeyValueType = Type.Object({
    key: Type.String(),
    value: Type.String(),
    isFixedKey: Type.Optional(Type.Boolean()),
})

export const ExtractorInfoType = Type.Object({
    display_name: Type.String(),
    extractor_name: Type.String(),
    id: Type.String(),
    model_id: Type.String(),
    model_name: Type.String(),
    only_file_processable: Type.Boolean(),
    service_name: Type.String(),
})

export const ExtractorResultType = Type.Object({
    content: Type.String(),
    fields: Type.Union([
        Type.Record(Type.String(), Type.Any()),
        Type.Object({}),
    ]),
    items: Type.Array(Type.Object({})),
})

export const ExtractorPagesType = Type.Object({
    incomplete_processed: Type.Boolean(),
    page_number: Type.Number(),
    text: Type.String(),
})

export const ProcessorResultType = Type.Object({
    extractor_info: ExtractorInfoType,
    extractor_result: Type.Object({
        documents: Type.Array(ExtractorResultType),
        file_name: Type.String(),
        pages: Type.Array(ExtractorPagesType),
    }),
})

export type KeyValueType = Static<typeof KeyValueType>
export type FileDataType = Static<typeof FileDataType>
export type ProcessorResultType = Static<typeof ProcessorResultType>
