<div class="ap-w-full ap-flex ap-flex-col ap-mt-[72px] ap-mb-24">
  <div
    class="ap-flex ap-flex-row ap-justify-between ap-items-center ap-font-inter ap-font-semibold"
  >
    <div class="ap-text-xl ap-text-[#344054]">Our agent templates</div>
    <ap-button
      actionButton
      btnSize="large"
      i18n
      btnStyle="basic"
      btnColor="basic"
      [fullWidthOfContainer]="true"
      (buttonClicked)="startBlankAgent()"
    >
      <div
        class="ap-flex ap-items-center ap-text-lg ap-font-semibold ap-rounded-2xl ap-font-inter ap-gap-3"
      >
        <svg-icon
          class="ap-fill-body ap-w-[24px] ap-h-[24px]"
          [applyClass]="true"
          src="assets/img/newDesign/onboarding/add.svg"
        >
        </svg-icon>
        Blank Agent
      </div>
    </ap-button>
  </div>

  <ng-container *ngIf="loading">
    <div
      class="ap-w-full ap-grid ap-gap-4 ap-mt-[35px] ap-grid-cols-1 sm:ap-grid-cols-2 lg:ap-grid-cols-3"
    >
      <ng-container *ngFor="let skeleton of [1, 2, 3]">
        <ap-template-skeleton />
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="templatesList$ | async as templates">
    <div
      class="ap-w-full ap-grid ap-gap-4 ap-mt-[35px] ap-grid-cols-1 sm:ap-grid-cols-2 lg:ap-grid-cols-3"
    >
      <ng-container *ngFor="let template of templates">
        <app-agent-card
          [template]="template"
          [action]="handleSelectAgentTemplate.bind(this)"
        />
      </ng-container>
      <div *ngIf="!loading && templates.length === 0">
        No templates available.
      </div>
    </div>
  </ng-container>
</div>
