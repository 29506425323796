<div
  class="ap-flex ap-flex-col ap-items-center ap-justify-center custom-column-description"
>
  <mat-form-field class="ap-flex-1 ap-w-full ap-max-h-[60px]">
    <mat-select
      [(value)]="selectedValue"
      (selectionChange)="onSelectChange($event.value)"
    >
      <mat-option *ngFor="let option of options" [value]="option">
        {{ option }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
