import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-product-number-cell',
  templateUrl: './product-number-cell.component.html',
  styleUrls: ['./product-number-cell.component.scss'],
})
export class ProductNumberCellComponent implements ICellRendererAngularComp {
  params: any;
  selectedValue: string = '';
  options: string[] = [];

  agInit(params: any): void {
    this.params = params;
    this.options = params?.value?.list ?? [];

    this.selectedValue = this.validateSelectedValue(
      params?.value?.selectedProductNumber
    );
  }

  refresh(params: any): boolean {
    this.params = params;
    this.options = params?.value?.list ?? [];
    this.selectedValue = this.validateSelectedValue(
      params?.value?.selectedProductNumber
    );
    return true;
  }

  validateSelectedValue(selected: string | undefined): string {
    return selected && this.options.includes(selected)
      ? selected
      : this.options[0] ?? '';
  }

  onSelectChange(selectedValue: string) {
    this.selectedValue = selectedValue;
    if (this.params.onSelectionChange) {
      this.params.onSelectionChange(selectedValue);
    }
  }
}
