import { Static, Type } from '@sinclair/typebox'
import {
    ExtractorInfoType,
    ExtractorPagesType,
    ExtractorResultType,
    FileDataType,
    ProcessorResultType,
} from './extractor'

export const AzureBlobQueryParamsType = Type.Object({
    identifier: Type.String(),
    container_name: Type.String(),
})

export const AzureBlobResultType = Type.Object({
    results: Type.Array(FileDataType),
    status: Type.Number(),
})

export type AzureBlobQueryParamsType = Static<typeof AzureBlobQueryParamsType>
export type AzureBlobResultType = Static<typeof AzureBlobResultType>

// -------------------------------------------------

export const AzureGetBlobQueryParamsType = Type.Object({
    identifier: Type.String(),
    container_name: Type.String(),
    file_name: Type.String(),
})

export const AzureGetBlobResultType = Type.Object({
    results: Type.String(),
    status: Type.Number(),
})

export type AzureGetBlobQueryParamsType = Static<
    typeof AzureGetBlobQueryParamsType
>
export type AzureGetBlobResultType = Static<typeof AzureGetBlobResultType>

// -------------------------------------------------

export const GetExtractorResultQueryParamsType = Type.Object({
    file_name: Type.String(),
    processor_id: Type.String(),
})

export const GetExtractorResultType = Type.Object({
    id: Type.String(),
    processor_result: ProcessorResultType,
    processor_type: Type.String(),
    status: Type.Number(),
})

export type GetExtractorResultQueryParamsType = Static<
    typeof GetExtractorResultQueryParamsType
>
export type GetExtractorResultType = Static<typeof GetExtractorResultType>

// -------------------------------------------------

export const PostAzureBlobQueryParamsType = Type.Object({
    file: Type.Any(), // Represents a binary file (Blob or File)
    container_name: Type.String(),
})

export const PostAzureBlobResultType = Type.Object({
    status: Type.Number(),
})

export type PostAzureBlobQueryParamsType = Static<
    typeof PostAzureBlobQueryParamsType
>
export type PostAzureBlobResultType = Static<typeof PostAzureBlobResultType>

// -------------------------------------------------

export const PostExtractorQueryParamsType = Type.Object({
    extractor_id: Type.String(),
    save_in_db: Type.String(),
})

export const PostExtractorPayloadType = Type.Object({
    file: Type.Any(),
    extractor_id: Type.String(),
})

export const PostExtractorResultType = Type.Object({
    extractor_info: ExtractorInfoType,
    extractor_result: Type.Object({
        documents: Type.Array(ExtractorResultType),
        file_name: Type.String(),
        pages: Type.Array(ExtractorPagesType),
    }),
    result_id: Type.String(),
    status: Type.Number(),
})

export type PostExtractorQueryParamsType = Static<
    typeof PostExtractorQueryParamsType
>
export type PostExtractorPayloadType = Static<typeof PostExtractorPayloadType>
export type PostExtractorResultType = Static<typeof PostExtractorResultType>

// -------------------------------------------------

export const SaveExtractorPayloadType = Type.Object({
    id: Type.String(),
    updated_extractor_result: Type.Object({
        documents: Type.Array(ExtractorResultType),
        file_name: Type.String(),
        pages: Type.Array(ExtractorPagesType),
    }),
})

export const SaveExtractorResultType = Type.Object({
    msg: Type.String(),
    status: Type.Number(),
})

export type SaveExtractorPayloadType = Static<typeof SaveExtractorPayloadType>
export type SaveExtractorResultType = Static<typeof SaveExtractorResultType>

// -------------------------------------------------

export const PublishExtractorPayloadType = Type.Object({
    id: Type.String(),
})

export type PublishExtractorPayloadType = Static<
    typeof PublishExtractorPayloadType
>
