import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'ap-add-new-extractor-row',
  templateUrl: './add-new-extractor-row.component.html',
  styleUrls: ['./add-new-extractor-row.component.scss'],
})
export class AddNewExtractorRowComponent implements OnInit {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddNewExtractorRowComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      firstInputTitle: string;
      hasCheckbox?: boolean;
    }
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      inputValue: [''],
      description: [''],
    });

    // Add the hasCheckbox control only if the checkbox should be displayed
    if (this.data.hasCheckbox) {
      this.form.addControl('hasCheckbox', this.fb.control(false));
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.form.valid) {
      const formValue = this.form.value;

      if (!this.data.hasCheckbox) {
        delete formValue.hasCheckbox;
      }

      this.dialogRef.close(formValue);
    }
  }
}
