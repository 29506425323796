/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    AttachmentType,
    UpBrainsMapperMessageType,
    UpbrainsMapperOutput,
    FrontMessage,
    OutlookMessage,
    ZendeskTicket,
    ZendeskComment,
} from '../upbrains-mapper'
import { convert } from 'html-to-text'

function processBodyContent(body: string): string {
    try {
        const isHtml = /<\/?[a-z][\s\S]*>/i.test(body);
        if (isHtml) {

            const textContent = convert(body, {
                wordwrap: false,
            });
            return textContent.trim();
        }
        return body;
    } catch (error) {
        console.error('Error processing body content:', error);
        return body;
    }
}

function categorizeContentType(contentType: string): AttachmentType {
    const lowerContentType = contentType.toLowerCase()

    // Image types
    if (lowerContentType.includes('image/')) return AttachmentType.IMAGE

    // PDF types
    if (lowerContentType.includes('application/pdf')) return AttachmentType.PDF

    // Doc types (Microsoft Office and similar document formats)
    const docTypes = [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-word',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Excel
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PowerPoint
        'application/vnd.ms-powerpoint',
    ]
    if (docTypes.some((type) => lowerContentType.includes(type)))
        return AttachmentType.DOC

    // Text-based types
    const textTypes = [
        'text/plain',
        'text/html',
        'text/csv',
        'application/json',
        'application/xml',
        'text/markdown',
    ]
    if (textTypes.some((type) => lowerContentType.includes(type)))
        return AttachmentType.TEXT

    // Default to other
    return AttachmentType.OTHER
}

// Mapper function to convert Zendesk Comment to UpbrainsMapperOutput
function mapZendeskCommentToUpbrains(
    input: ZendeskComment,
): UpbrainsMapperOutput {
    const lastComment = input.lastComment

    return {
        event_type: 'inbound',
        integration_name: 'Zendesk',
        ticket_or_conversation_id: lastComment.audit_id.toString(),
        comment_or_message_id: lastComment.id.toString(),
        inbox_or_group_id: lastComment.via.source.to.name, // Using 'to' name as group/inbox identifier
        channel_type: lastComment.via.channel,
        channel_address: lastComment.via.source.to.address,
        from: lastComment.via.source.from as {
            name: string
            email: string
        },
        to: [
            {
                name: lastComment.via.source.to.name,
                email: lastComment.via.source.to.address,
            },
        ],
        subject: '', // Zendesk comment example doesn't provide a subject
        body: processBodyContent(lastComment.body),
        html_body: lastComment.html_body,
        attachments: lastComment.attachments.map((attachment: any) => ({
            id: attachment.id?.toString() || '',
            integration_name: 'Zendesk',
            name: attachment.filename || '',
            size: attachment.size || 0,
            content_url: attachment.content_url || '',
            content_type: categorizeContentType(attachment.content_type || ''),
            is_inline: attachment.is_inline || false,
        })),
        timestamp: new Date(lastComment.created_at).getTime(),
        tags: [], // No tags in the provided example
    }
}

export function mapFrontMessageToUpbrains(
    message: FrontMessage,
): UpbrainsMapperOutput {
    return {
        event_type: 'inbound',
        integration_name: 'Front',
        ticket_or_conversation_id: message.conversation.id,
        comment_or_message_id: message.target.data.id,
        inbox_or_group_id: message.source.data[0]?.id || '',
        channel_type: message.target.data.type,
        channel_address: message.source.data[0]?.address || '',
        from: {
            name: message.conversation.recipient.name,
            email: message.conversation.recipient.handle,
        },
        to: message.target.data.recipients
            .filter((r) => r.role === 'to')
            .map((r) => ({
                name: r.name,
                email: r.handle,
            })),
        subject: message.conversation.subject,
        body: processBodyContent(message.target.data.body),
        html_body: message.target.data.body,
        attachments: message.target.data.attachments.map((attachment) => ({
            id: attachment.id,
            integration_name: 'Front',
            name: attachment.filename,
            size: attachment.size,
            content_url: attachment.url,
            content_type: categorizeContentType(attachment.content_type),
            is_inline: attachment.metadata.is_inline,
        })),
        timestamp: message.target.data.created_at,
        tags: message.conversation.tags,
    }
}

export function mapOutlookMessageToUpbrains(
    message: OutlookMessage,
): UpbrainsMapperOutput {
    return {
        event_type: 'inbound',
        integration_name: 'Microsoft',
        ticket_or_conversation_id: message.conversationId,
        comment_or_message_id: message.id,
        inbox_or_group_id: message.parentFolderId,
        channel_type: 'email',
        channel_address: message.toRecipients[0]?.emailAddress?.address || '',
        from: {
            name: message.from.emailAddress.name,
            email: message.from.emailAddress.address,
        },
        to: message.toRecipients.map((recipient) => ({
            name: recipient.emailAddress.name,
            email: recipient.emailAddress.address,
        })),
        subject: message.subject,
        body: processBodyContent(message.body.content),
        html_body: message.body.content,
        attachments:
            message.attachments?.map((attachment) => ({
                id: attachment.id,
                integration_name: 'Microsoft',
                name: attachment.name,
                size: attachment.size,
                content_url: attachment.contentURL
                    ? attachment.contentURL
                    : '',
                content_type: categorizeContentType(
                    attachment.contentType ? attachment.contentType : attachment['@odata.mediaContentType'],
                ),
                is_inline: attachment.isInline,
            })) || [],
        timestamp: new Date(message.sentDateTime).getTime() / 1000,
        tags: message.ccRecipients ? ['cc_mail'] : [],
    }
}

export function mapZendeskTicketToUpbrains(
    ticket: ZendeskTicket,
): UpbrainsMapperOutput {
    return {
        event_type: 'inbound',
        integration_name: 'Zendesk',
        ticket_or_conversation_id: ticket.ticket.id.toString(),
        comment_or_message_id: ticket.ticket.id.toString(),
        inbox_or_group_id: ticket.ticket.group_id?.toString() || '',
        channel_type: ticket.ticket.via.channel,
        channel_address: '', // Zendesk doesn't provide a clear channel address in this structure
        from: {
            name: '', // Zendesk ticket doesn't provide sender name in this structure
            email: '', // Zendesk ticket doesn't provide sender email in this structure
        },
        to: [], // Zendesk ticket doesn't specify recipients in this structure
        subject: ticket.ticket.subject,
        body: processBodyContent(ticket.ticket.description),
        html_body: ticket.ticket.description,
        attachments: [], // No attachments in this example
        timestamp: new Date(ticket.ticket.created_at).getTime() / 1000,
        tags: ticket.ticket.tags,
    }
}

// Utility function to map to UpbrainsMapperOutput
export function serializeMessage({
    type,
    data,
}: {
    type: UpBrainsMapperMessageType
    data: FrontMessage | OutlookMessage | ZendeskTicket | ZendeskComment
}): UpbrainsMapperOutput {
    switch (type) {
        case UpBrainsMapperMessageType.FRONT:
            return mapFrontMessageToUpbrains(data as FrontMessage)
        case UpBrainsMapperMessageType.OUTLOOK:
            return mapOutlookMessageToUpbrains(data as OutlookMessage)
        case UpBrainsMapperMessageType.ZENDESK_COMMENT:
            return mapZendeskCommentToUpbrains(data as ZendeskComment)
        case UpBrainsMapperMessageType.ZENDESK_TICKET:
            return mapZendeskTicketToUpbrains(data as ZendeskTicket)
        default:
            throw new Error(`Unsupported message type: ${type}`)
    }
}
