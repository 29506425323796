<div
  class="ap-bg-[#FCFCFD] !ap-font-inter ap-relative ap-w-full ap-max-h-screen ap-h-[100vh] custom-onboarding-inner ap-grid ap-pt-24 ap-place-items-center ap-px-[80px]"
  style="
    grid-template-rows: max-content max-content max-content;
    grid-template-columns: 1fr;
  "
  [ngClass]="{ 'ap-overflow-y-auto': revealComponent }"
>
  <ng-container *ngIf="isBackNavigationAllowed">
    <!-- prev button -->
    <ap-icon-button
      tooltipText="Previous Step"
      i18n-tooltipText
      iconUrl="assets/img/newDesign/onboarding/arrow-left.svg"
      [width]="24"
      [height]="24"
      class="ap-rounded-lg ap-fixed ap-top-14 ap-left-10 custom-skip-button"
      (buttonClicked)="prevStep()"
    ></ap-icon-button>
  </ng-container>

  <ng-container *ngIf="canSkip">
    <!-- skip button -->
    <ap-button
      actionButton
      btnSize="large"
      i18n
      btnColor="primary"
      btnStyle="basic"
      class="ap-rounded-lg ap-fixed ap-top-14 ap-right-10 custom-skip-button"
      [fullWidthOfContainer]="true"
      (buttonClicked)="handleSkip()"
    >
      <div
        class="ap-flex ap-items-center loading-template ap-text-lg ap-font-semibold ap-rounded-2xl ap-font-inter"
      >
        skip
      </div>
    </ap-button>
  </ng-container>

  <ng-container *ngIf="_config.title">
    <div
      class="ap-flex ap-flex-row ap-justify-center ap-items-center ap-gap-[3.5px] ap-h-max ap-self-center ap-max-h-max"
    >
      <!-- TITLE -->
      <div class="ap-w-[45px] ap-h-[45px]">
        <svg-icon
          [svgStyle]="{ width: '100%', height: '100%' }"
          class="ap-flex ap-justify-center ap-items-center"
          src="assets/img/newDesign/onboarding/sparkle-large.svg"
        ></svg-icon>
      </div>
      <p class="ap-text-3xl ap-font-medium ap-font-inter">
        {{ _config.title }}
      </p>
    </div>
  </ng-container>

  <ng-container *ngIf="_config.subTitle">
    <p class="ap-text-base ap-font-medium ap-font-inter">
      {{ _config.subTitle }}
    </p>
  </ng-container>

  <div
    class="ap-text-5xl ap-mt-[25px] ap-py-[3px] ap-font-bold typing-text custom-title-color ap-text-center ap-tracking-[-0.02em] !ap-font-inter ap-leading-[60px]"
  >
    {{ displayedText }}
  </div>

  <!-- revealComponent -->
  <div
    class="dynamic-component-container ap-h-max ap-mx-auto ap-max-w-[1280px]"
    [ngClass]="{ 'fade-in-from-bottom': revealComponent }"
  >
    <ng-template #dynamicComponentContainer></ng-template>
  </div>
</div>
