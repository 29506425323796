<div
  *ngIf="leftColumnIdentifiers?.length || rightColumnIdentifiers?.length"
  class="ap-p-7"
>
  <div class="ap-flex ap-items-center ap-justify-between">
    <div
      class="ap-text-gray-900 ap-text-base ap-font-bold ap-font-['Inter'] ap-leading-normal"
    >
      {{ title }}
    </div>
    <!-- <button
      class="ap-h-9 ap-px-3.5 ap-py-2 ap-bg-blue-600 ap-rounded-lg ap-shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] ap-border ap-border-blue-600 ap-text-white ap-text-sm ap-font-semibold ap-font-['Inter'] ap-leading-tight"
    >
      More order status
    </button> -->
  </div>
  <div
    class="ap-text-gray-400 ap-text-sm ap-font-normal ap-font-['Inter'] ap-leading-tight ap-mt-8"
  >
    {{ highlightsInput.summary }}
  </div>

  <div
    class="ap-flex ap-flex-wrap ap-items-start ap-gap-8 md:ap-gap-16 lg:ap-gap-24"
  >
    <!-- Left Column -->
    <div
      class="left ap-flex ap-flex-col ap-gap-4 ap-min-w-[40%] ap-max-w-[50%]"
    >
      <div
        class="ap-flex ap-items-center ap-gap-4 md:ap-gap-8"
        *ngFor="let identifier of leftColumnIdentifiers"
      >
        <div
          class="ap-flex-1 ap-text-gray-900 ap-text-xs ap-font-medium ap-font-['Inter'] ap-truncate"
        >
          {{ identifier.identifierId }}
        </div>
        <div
          class="ap-flex-1 ap-text-gray-500 ap-text-xs ap-font-medium ap-font-['Inter'] ap-truncate"
        >
          {{ identifier.identifierName }}
        </div>
      </div>
    </div>

    <!-- Right Column -->
    <div
      class="right ap-flex ap-flex-col ap-gap-4 ap-min-w-[40%] ap-max-w-[50%]"
    >
      <div
        class="ap-flex ap-items-center ap-gap-4 md:ap-gap-8"
        *ngFor="let identifier of rightColumnIdentifiers"
      >
        <div
          class="ap-flex-1 ap-text-gray-900 ap-text-xs ap-font-medium ap-font-['Inter'] ap-truncate"
        >
          {{ identifier.identifierId }}
        </div>
        <div
          class="ap-flex-1 ap-text-gray-500 ap-text-xs ap-font-medium ap-font-['Inter'] ap-truncate"
        >
          {{ identifier.identifierName }}
        </div>
      </div>
    </div>
  </div>
</div>
