<div
  class="ap-w-[666px] ap-rounded-xl ap-p-6 ap-flex ap-flex-col ap-gap-6 custom-add-item"
  [formGroup]="form"
>
  <div
    class="ap-text-[#101828] ap-text-lg ap-font-semibold ap-font-inter ap-flex ap-flex-row ap-justify-center ap-items-center"
  >
    Add New {{ data.title }}
  </div>

  <div class="ap-flex ap-flex-row ap-gap-6">
    <div class="ap-flex ap-flex-col ap-gap-[6px] ap-flex-1">
      <div class="ap-text-[#344054] ap-text-sm ap-font-inter ap-font-medium">
        {{ data.firstInputTitle }}
      </div>
      <input
        formControlName="inputValue"
        class="ap-rounded-lg ap-border ap-border-[#D0D5DD] ap-bg-white ap-py-3 ap-px-4 custom-add-item-input ap-text-[#667085] ap-text-base ap-font-normal ap-font-inter"
      />
    </div>

    <div class="ap-flex ap-flex-col ap-gap-[6px] ap-flex-1">
      <div class="ap-text-[#344054] ap-text-sm ap-font-inter ap-font-medium">
        Type
      </div>
      <select
        class="ap-rounded-lg ap-border ap-border-[#D0D5DD] ap-bg-white ap-py-3 ap-px-4 custom-add-item-input ap-text-[#667085] ap-text-base ap-font-normal ap-font-inter"
      >
        <option value="text">Text</option>
      </select>
    </div>
  </div>

  <mat-checkbox
    *ngIf="data.hasCheckbox"
    color="primary"
    formControlName="hasCheckbox"
    i18n
  >
    Expecting more than one value
  </mat-checkbox>

  <div class="ap-flex ap-flex-col ap-gap-[6px] ap-flex-1">
    <div class="ap-text-[#344054] ap-text-sm ap-font-inter ap-font-medium">
      Description (Optional)
    </div>
    <textarea
      rows="4"
      formControlName="description"
      placeholder="Enter a description..."
      class="ap-rounded-lg ap-border ap-border-[#D0D5DD] ap-bg-white ap-py-3 ap-px-4 custom-add-item-input ap-text-[#667085] ap-text-base ap-font-normal ap-font-inter"
    ></textarea>

    <div
      class="ap-flex ap-flex-row ap-justify-center ap-items-center ap-gap-6 ap-mt-8"
    >
      <ap-button
        actionButton
        btnSize="large"
        i18n
        btnColor="basic"
        class="ap-rounded-lg"
        [fullWidthOfContainer]="true"
        (click)="onCancel()"
      >
        <div
          class="ap-flex ap-items-center ap-text-[15px] ap-font-semibold ap-rounded-lg ap-font-inter"
        >
          Cancel
        </div>
      </ap-button>

      <div class="custom-button">
        <ap-button
          actionButton
          btnSize="large"
          i18n
          class="ap-rounded-lg"
          [fullWidthOfContainer]="true"
          (click)="onSubmit()"
        >
          <div
            class="ap-flex ap-items-center ap-text-[15px] ap-font-semibold ap-rounded-lg ap-font-inter"
          >
            Add to list
          </div>
        </ap-button>
      </div>
    </div>
  </div>
</div>
