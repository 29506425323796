import { createAction, props } from '@ngrx/store';
import { ExtractorActionType, ExtractorList } from '@upbrains/shared';

const setSelectedExtractor = createAction(
  '[Extractors] Set Selected Extractor',
  props<{
    selectedExtractor: ExtractorList | null;
  }>()
);

const setExtractorList = createAction(
  '[Extractors] Set Extractor List',
  props<{
    extractorList: ExtractorList[];
  }>()
);

const setSelectedExtractorMode = createAction(
  '[Extractors] Set Selected Extractor Mode',
  props<{
    selectedExtractorMode: ExtractorActionType;
  }>()
);

export const extractorsActions = {
  setSelectedExtractor,
  setExtractorList,
  setSelectedExtractorMode,
};
