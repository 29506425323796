<div
  class="ap-w-[586px] ap-bg-[#ffffff] ap-rounded-[20px] ap-border ap-border-[#DCE0E9]"
>
  <div
    class="ap-flex ap-flex-row ap-justify-between ap-border-b ap-border-[#E2E8F0] ap-py-[10px] ap-px-[14px]"
  >
    <div class="ap-flex ap-flex-row ap-gap-2">
      <ngx-skeleton-loader
        [theme]="{ height: '27px', width: '142px', marginBottom: '0px' }"
      />
      <ngx-skeleton-loader
        [theme]="{ height: '27px', width: '142px', marginBottom: '0px' }"
      />
    </div>

    <div class="ap-text-sm ap-font-medium ap-text-[#475569] ap-font-inter">
      CC
    </div>
  </div>

  <div
    class="ap-flex ap-flex-col ap-px-[26px] ap-py-5 ap-font-normal ap-font-inter ap-text-sm"
  >
    <ngx-skeleton-loader
      [theme]="{ height: '31px', width: '100%', marginBottom: '8px' }"
    />

    <ngx-skeleton-loader
      [theme]="{ height: '24px', width: '90%', marginBottom: '8px' }"
    />
    <ngx-skeleton-loader
      [theme]="{ height: '24px', width: '60%', marginBottom: '8px' }"
    />
    <ngx-skeleton-loader
      [theme]="{ height: '24px', width: '70%', marginBottom: '8px' }"
    />
    <ngx-skeleton-loader
      [theme]="{ height: '24px', width: '30%', marginBottom: '0px' }"
    />

    <div class="ap-mb-12"></div>

    <ngx-skeleton-loader
      [theme]="{ height: '24px', width: '80%', marginBottom: '8px' }"
    />
    <ngx-skeleton-loader
      [theme]="{ height: '24px', width: '70%', marginBottom: '8px' }"
    />
    <ngx-skeleton-loader
      [theme]="{ height: '24px', width: '20%', marginBottom: '8px' }"
    />

    <div class="ap-flex ap-flex-row ap-gap-3">
      <ngx-skeleton-loader [theme]="{ height: '108px', width: '179px' }" />
      <ngx-skeleton-loader [theme]="{ height: '108px', width: '179px' }" />
    </div>
  </div>
</div>
