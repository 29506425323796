<ng-container
  *ngIf="signedUpEnabled$ | async as signedUpEnabled; else signUpDisabled"
>
  <ng-container *ngIf="!signUpDone; else verificationTemplate">
    <div
      class="ap-text-3xl ap-font-semibold ap-mt-6 ap-font-inter ap-text-center"
      i18n
    >
      Create an account
    </div>
    <div
      class="ap-text-sm ap-font-normal ap-mt-3 ap-font-inter ap-flex ap-flex-row ap-justify-center ap-items-center"
      i18n
    >
      Already have an account?
      <a
        class="ap-ml-1 ap-text-sm ap-font-semibold ap-text-[#175CD3] !ap-no-underline"
        routerLink="/sign-in"
        >Log in</a
      >
    </div>
    <ap-third-party-auth [isForSignup]="true"></ap-third-party-auth>
    <app-authentication-methods-separator></app-authentication-methods-separator>
    <form
      *ngIf="emailLoginsEnabled$ | async"
      [formGroup]="registrationForm"
      (ngSubmit)="signUp()"
      class="ap-flex ap-flex-col ap-gap-2 ap-mt-6 ap-font-inter custom-sign-up"
    >
      <div class="">
        <div class="ap-grid ap-grid-cols-2 ap-gap-4">
          <div>
            <div class="ap-text-sm ap-font-medium">First Name*</div>
            <mat-form-field
              class="ap-w-full md:ap-w-initial"
              appearance="outline"
            >
              <input matInput placeholder="John" formControlName="firstName" />
              <mat-error
                *ngIf="registrationForm.controls.firstName.hasError('required')"
                i18n
              >
                First name is required
              </mat-error>
              <mat-error
                *ngIf="
                  registrationForm.controls.firstName.hasError('maxlength')
                "
                i18n
              >
                Max 50 characters
              </mat-error>
            </mat-form-field>
          </div>
          <div>
            <div class="ap-text-sm ap-font-medium">Last Name*</div>
            <mat-form-field
              class="ap-w-full md:ap-w-initial"
              appearance="outline"
            >
              <input matInput placeholder="Doe" formControlName="lastName" />
              <mat-error
                *ngIf="registrationForm.controls.lastName.hasError('required')"
                i18n
              >
                Last name is required
              </mat-error>
              <mat-error
                *ngIf="
                  registrationForm.controls.firstName.hasError('maxlength')
                "
                i18n
              >
                Max 50 characters
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="ap-text-sm ap-font-medium">Company Name*</div>
        <mat-form-field class="ap-w-full" appearance="outline">
          <input
            matInput
            placeholder="Company Name"
            i18n-placeholder
            formControlName="companyName"
          />

          <mat-error *ngIf="registrationForm.controls.companyName.invalid">
            <ng-container
              *ngIf="
                !registrationForm.controls.companyName.hasError('required')
              "
              i18n
            >
              Company Name is too long</ng-container
            >
            <ng-container
              *ngIf="
                registrationForm.controls.companyName.hasError('maxLength')
              "
              i18n
              >Max 50 characters</ng-container
            >
          </mat-error>
        </mat-form-field>

        <div class="ap-text-sm ap-font-medium">Email*</div>
        <mat-form-field class="ap-w-full" appearance="outline">
          <input
            matInput
            placeholder="Email"
            i18n-placeholder
            formControlName="email"
          />

          <mat-error *ngIf="registrationForm.controls.email.invalid">
            <ng-container
              *ngIf="
                registrationForm.controls.email.getError('email') ||
                registrationForm.controls.email.getError('invalidEmail')
              "
              i18n
            >
              Email is invalid
            </ng-container>
            <ng-container
              *ngIf="
                registrationForm.controls.email.getError(emailIsUsedErrorName)
              "
              i18n
            >
              Email is used
            </ng-container>
            <ng-container
              *ngIf="registrationForm.controls.email.getError('required')"
              i18n
              >Email is required</ng-container
            >
          </mat-error>
        </mat-form-field>

        <div class="ap-text-sm ap-font-medium">Password*</div>
        <mat-form-field class="ap-w-full" appearance="outline">
          <input
            (focus)="menuTrigger.openMenu()"
            (blur)="menuTrigger.closeMenu()"
            type="password"
            matInput
            placeholder="Password"
            i18n-placeholder
            formControlName="password"
          />
          <mat-error *ngIf="registrationForm.controls['password'].invalid">
            <ng-container
              *ngIf="getPasswordError('required'); else invalidPasswordMessage"
              i18n
            >
              Password is required
            </ng-container>
            <ng-template #invalidPasswordMessage i18n
              >Password is invalid</ng-template
            >
          </mat-error>
          <div
            #menuTrigger="matMenuTrigger"
            [matMenuTriggerFor]="menu"
            class="hidden-trigger"
          ></div>
        </mat-form-field>

        <div class="ap-text-sm ap-font-medium">Confirm Password*</div>
        <mat-form-field class="ap-w-full ap-mb-4" appearance="outline">
          <input
            type="password"
            matInput
            placeholder="Confirm Password"
            i18n-placeholder
            formControlName="confirmPassword"
          />
          <mat-error *ngIf="getConfirmPasswordError('required')" i18n>
            Confirm Password is required
          </mat-error>
          <mat-error *ngIf="hasPasswordMismatchError()" i18n>
            Passwords do not match
          </mat-error>
        </mat-form-field>

        <mat-menu #menu="matMenu" [hasBackdrop]="true">
          <div class="ap-mx-2">
            <div
              class="ap-flex ap-gap-4 ap-items-center"
              [class.invalid-password-condition]="getPasswordError('lowercase')"
            >
              <svg-icon
                [svgStyle]="{ width: '13px', height: '13px' }"
                src="assets/img/custom/auth/checks/check-invalid.svg"
                class="grey-check"
              >
              </svg-icon>
              <svg-icon
                [svgStyle]="{ width: '13px', height: '13px' }"
                src="assets/img/custom/auth/checks/check-correct.svg"
                class="green-check"
              >
              </svg-icon>
              <span i18n>Lowercase</span>
            </div>
            <div
              class="ap-flex ap-gap-4 ap-items-center"
              [class.invalid-password-condition]="getPasswordError('uppercase')"
            >
              <svg-icon
                [svgStyle]="{ width: '13px', height: '13px' }"
                src="assets/img/custom/auth/checks/check-invalid.svg"
                class="grey-check"
              >
              </svg-icon>
              <svg-icon
                [svgStyle]="{ width: '13px', height: '13px' }"
                src="assets/img/custom/auth/checks/check-correct.svg"
                class="green-check"
              >
              </svg-icon>
              <span i18n>Uppercase</span>
            </div>
            <div
              class="ap-flex ap-gap-4 ap-items-center"
              [class.invalid-password-condition]="
                getPasswordError('specialCharacter')
              "
            >
              <svg-icon
                [svgStyle]="{ width: '13px', height: '13px' }"
                src="assets/img/custom/auth/checks/check-invalid.svg"
                class="grey-check"
              >
              </svg-icon>
              <svg-icon
                [svgStyle]="{ width: '13px', height: '13px' }"
                src="assets/img/custom/auth/checks/check-correct.svg"
                class="green-check"
              >
              </svg-icon>
              <span i18n>Special Character</span>
            </div>
            <div
              class="ap-flex ap-gap-4 ap-items-center"
              [class.invalid-password-condition]="getPasswordError('number')"
            >
              <svg-icon
                [svgStyle]="{ width: '13px', height: '13px' }"
                src="assets/img/custom/auth/checks/check-invalid.svg"
                class="grey-check"
              >
              </svg-icon>
              <svg-icon
                [svgStyle]="{ width: '13px', height: '13px' }"
                src="assets/img/custom/auth/checks/check-correct.svg"
                class="green-check"
              >
              </svg-icon>
              <span i18n>Number</span>
            </div>
            <div
              class="ap-flex ap-gap-4 ap-items-center"
              [class.invalid-password-condition]="
                getPasswordError('maxlength') ||
                getPasswordError('minlength') ||
                getPasswordError('required')
              "
            >
              <svg-icon
                [svgStyle]="{ width: '13px', height: '13px' }"
                src="assets/img/custom/auth/checks/check-invalid.svg"
                class="grey-check"
              >
              </svg-icon>
              <svg-icon
                [svgStyle]="{ width: '13px', height: '13px' }"
                src="assets/img/custom/auth/checks/check-correct.svg"
                class="green-check"
              >
              </svg-icon>
              <span i18n>8-64 Characters</span>
            </div>
          </div>
        </mat-menu>

        <mat-checkbox
          id="has_agreement"
          color="primary"
          formControlName="hasAgreement"
          i18n
          class="ap-my-2"
        >
          <span
            >I have read and agree to the UpBrains
            <a
              class="ap-text-sm ap-font-semibold ap-text-[#175CD3] ap-no-underline"
              href="https://upbrains.ai/terms"
              target="_blank"
              >Terms of Service</a
            >
            and understand that my personal information is processed in
            accordance with
            <a
              class="ap-text-sm ap-font-semibold ap-text-[#175CD3] ap-no-underline"
              href="https://upbrains.ai/privacypolicy"
              target="_blank"
              >Privacy Policy</a
            ></span
          >
        </mat-checkbox>

        <mat-checkbox
          *ngIf="showNewsLetterCheckbox$ | async"
          id="news_letter"
          color="primary"
          formControlName="newsLetter"
          i18n
        >
          I agree to receive email and marketing communication from UpBrains AI
          about their product and services.
        </mat-checkbox>

        <mat-error
          *ngIf="submitted && registrationForm.hasError('atLeastOneRequired')"
          class="ap-px-4 ap-text-xs ap-font-inter ap-font-light"
        >
          You must agree to one of the agreements at least.
        </mat-error>

        <div class="text-center ap-mt-2 ap-flex ap-flex-col ap-gap-2">
          <ap-button
            [loading]="loading"
            btnColor="primary"
            btnSize="large"
            [fullWidthOfContainer]="true"
            class="ap-text-lg ap-font-semibold"
            i18n
          >
            Let's Get Started
          </ap-button>
        </div>

        <mat-error
          class="ap-text-center"
          *ngIf="invitationOnlySignup && !emailChanged"
          i18n
        >
          You are not invited to any project, please contact your administrator.
        </mat-error>

        <mat-error
          class="ap-text-center"
          *ngIf="domainIsNotAllowed && !emailChanged"
          i18n
        >
          Your email domain is not allowed to sign up, please contact your
          administrator.
        </mat-error>
        <mat-error
          *ngIf="showNotFoundMemberOrProjectOwnerMessage"
          class="ap-text-center"
          i18n
          >You are not the owner or a member of any project.</mat-error
        >
        <mat-error
          *ngIf="
            !showNotFoundMemberOrProjectOwnerMessage &&
            !domainIsNotAllowed &&
            !invitationOnlySignup &&
            hasError
          "
          class="ap-text-center"
          i18n
          >Something went wrong! Please try again later.</mat-error
        >
      </div>
    </form>
  </ng-container>
</ng-container>

<ng-template #signUpDisabled>
  <div class="ap-text-center ap-mb-4 md" i18n>Direct access is disabled.</div>
  <div class="ap-text-center ap-mt-2" i18n>
    Please access through
    <a
      href="https://portal.upbrains.ai"
      rel="noopener noreferrer"
      class="ap-text-avatar ap-text-[16px]"
    >
      UpBrains AI Portal</a
    >.
  </div>
</ng-template>

<ng-template #verificationTemplate>
  <app-send-email-for-auth-action
    [email]="registrationForm.value.email!"
    [otpType]="OtpType.EMAIL_VERIFICATION"
  ></app-send-email-for-auth-action>
</ng-template>

<ng-container *ngIf="signUp$ | async"></ng-container>
<ng-container *ngIf="emailValueChanged$ | async"> </ng-container>
