import { Type } from '@sinclair/typebox'

export const MapMicrosoftMessageToUpBrainsRequest = Type.Object({
    '@odata.context': Type.String(),
    '@odata.etag': Type.Optional(Type.String()),
    id: Type.String(),
    createdDateTime: Type.String(),
    lastModifiedDateTime: Type.Optional(Type.String()),
    parentFolderId: Type.Optional(Type.String()),
    conversationId: Type.Optional(Type.String()),
    categories: Type.Optional(Type.Any()),
    subject: Type.String(),
    body: Type.Object({
        contentType: Type.String(),
        content: Type.String(),
    }),
    sender: Type.Object({
        emailAddress: Type.Object({
            name: Type.String(),
            address: Type.String(),
        }),
    }),
    from: Type.Object({
        emailAddress: Type.Object({
            name: Type.String(),
            address: Type.String(),
        }),
    }),
    toRecipients: Type.Array(
        Type.Object({
            emailAddress: Type.Object({
                name: Type.String(),
                address: Type.String(),
            }),
        }),
    ),
    ccRecipients: Type.Optional(
        Type.Array(
            Type.Object({
                emailAddress: Type.Object({
                    name: Type.String(),
                    address: Type.String(),
                }),
            }),
        ),
    ),
    bccRecipients: Type.Optional(
        Type.Array(
            Type.Object({
                emailAddress: Type.Object({
                    name: Type.String(),
                    address: Type.String(),
                }),
            }),
        ),
    ),
    hasAttachments: Type.Optional(Type.Boolean()),
    attachments: Type.Optional(
        Type.Array(
            Type.Object({
                id: Type.String(),
                name: Type.String(),
                size: Type.Number(),
                contentType: Type.String(),
                isInline: Type.Boolean(),
                contentBytes: Type.Optional(Type.String()),
            }),
        ),
    ),
    receivedDateTime: Type.String(),
    internetMessageId: Type.Optional(Type.String()),
    webLink: Type.Optional(Type.String()),
})
