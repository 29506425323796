<div class="ap-flex ap-flex-col ap-items-start custom-column-description">
  <ng-container *ngIf="content || selectedValue">
    <p class="ap-w-full">
      <strong class="ap-text-sm !ap-leading-8">Request: </strong
      >{{ content || selectedValue }}
    </p>
  </ng-container>

  <div class="ap-w-full ap-flex ap-flex-row ap-gap-2 ap-pr-1">
    <mat-form-field class="ap-flex-1 ap-w-full ap-max-h-[60px]">
      <input
        [(ngModel)]="model"
        type="text"
        placeholder="Choose/Add new option"
        aria-label="Request Description"
        matInput
        [matAutocomplete]="auto"
        (keydown)="handleKeydown($event)"
        (keyup)="keypress($event)"
        (ngModelChange)="filterOptions($event)"
      />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option
          *ngFor="let option of filteredOptions"
          [value]="option"
          (click)="setModel(option)"
          class="!ap-text-[#475466] !ap-text-sm !ap-font-medium !ap-font-inter"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</div>
