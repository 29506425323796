<form [formGroup]="errorHandlingOptionsForm">
  <div class="ap-flex ap-flex-col">
    <div
      apTrackHover
      #slider="hoverTrackerDirective"
      class="ap-flex ap-flex-col"
      *ngIf="!hideContinueOnFailure"
    >
      <mat-slide-toggle
        [formControl]="errorHandlingOptionsForm.controls.continueOnFailure"
        color="primary"
        class="ap-flex-grow-1"
        i18n
        >Continue on Failure</mat-slide-toggle
      >
      <div
        class="ap-flex ap-gap-2 ap-justify-between ap-markdown"
        [style.marginBottom]="
          BOTTOM_MARGIN_FOR_DESCRIPTION_IN_AGENT_PROPERTIES_FORM
        "
      >
        <div
          class="ap-line-clamp-1"
          [style.display]="
            continueOnFailureDescriptionExpanded ? 'block' : '-webkit-box'
          "
          [class.ap-h-[24px]]="!continueOnFailureDescriptionExpanded"
        >
          <div
            class="ap-typography-caption"
            apCheckOverflow
            (isOverflowed)="continueOnFailureDescriptionOverflows = $event"
          >
            <markdown i18n
              >Enable this option to skip this step and continue the flow
              normally if it fails.</markdown
            >
          </div>
        </div>
        <div
          class="ap-flex ap-items-start"
          *ngIf="
            continueOnFailureDescriptionExpanded ||
            continueOnFailureDescriptionOverflows
          "
        >
          <button
            class="ap-underline ap-typography-caption ap-mt-2"
            (click)="
              continueOnFailureDescriptionExpanded =
                !continueOnFailureDescriptionExpanded
            "
          >
            {{ continueOnFailureDescriptionExpanded ? 'less' : 'more' }}
          </button>
        </div>
      </div>
    </div>
    <div
      apTrackHover
      #slider="hoverTrackerDirective"
      class="ap-flex ap-flex-col ap-align-center"
      *ngIf="!hideRetryOnFailure"
    >
      <mat-slide-toggle
        [formControl]="errorHandlingOptionsForm.controls.retryOnFailure"
        color="primary"
        class="ap-flex-grow-1"
        i18n
        >Auto Retry on Failure</mat-slide-toggle
      >
      <div class="ap-flex ap-gap-2 ap-justify-between ap-markdown">
        <div class="ap-typography-caption">
          <markdown i18n
            >Automatically retry up to four attempts when failed.</markdown
          >
        </div>
      </div>
    </div>
    <div
      apTrackHover
      #slider="hoverTrackerDirective"
      class="ap-flex ap-flex-col ap-align-center"
      *ngIf="!hideInConverSation"
    >
      <mat-slide-toggle
        [formControl]="errorHandlingOptionsForm.controls.hideInConversation"
        color="primary"
        class="ap-flex-grow-1"
        i18n
        >Hide in conversation</mat-slide-toggle
      >
      <div class="ap-flex ap-gap-2 ap-justify-between ap-markdown">
        <div class="ap-typography-caption">
          <markdown i18n>Hide in conversation</markdown>
        </div>
      </div>
    </div>
  </div>
</form>

<ng-container *ngIf="valueChanges$ | async"></ng-container>
