export const environment = {
  igniteUrl: 'https://docreader.upbrainsai.com/',
  production: false,
  apiUrl: 'https://workflow-dev.upbrainsai.com/api/v1/',
  jwtTokenName: 'token',
  redirectUrl: 'https://workflow-dev.upbrainsai.com/redirect',
  userPropertyNameInLocalStorage: 'currentUser',
  extractUrl: 'https://xtract-dev.upbrainsai.com/',
  emailSenderFlowUrl:
    'https://workflow-dev.upbrainsai.com/api/v1/webhooks/aXklIhjGQsH2Al9Gi2oBn',
  calendlyScriptUrl: 'https://assets.calendly.com/assets/external/widget.js',
  calendlyDataUrl: 'https://calendly.com/upbrains-ai/30min',
  onboarding_email_recipient: 'info@upbrains.ai',
};
