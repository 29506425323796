import { createReducer, on } from '@ngrx/store';
import { extractorsActions } from './extractors.action';
import { ExtractorState } from './extractors-state.model';

export const extractorsInitialState: ExtractorState = {
  selectedExtractor: null,
  extractorList: [],
  selectedExtractorMode: null,
};

export const extractorsReducer = createReducer(
  extractorsInitialState,
  on(
    extractorsActions.setSelectedExtractor,
    (state, { selectedExtractor }): ExtractorState => {
      return {
        ...state,
        selectedExtractor,
      };
    }
  ),
  on(
    extractorsActions.setExtractorList,
    (state, { extractorList }): ExtractorState => ({
      ...state,
      extractorList,
    })
  ),
  on(
    extractorsActions.setSelectedExtractorMode,
    (state, { selectedExtractorMode }): ExtractorState => ({
      ...state,
      selectedExtractorMode,
    })
  )
);
