<div>
  <div
    class="ap-flex ap-flex-row ap-justify-between ap-items-center ap-gap-2 ap-mb-[22px] custom-search-conversation"
  >
    <mat-form-field
      subscriptSizing="dynamic"
      appearance="outline"
      (click)="$event.stopPropagation()"
      class="ap-w-[320px] ap-mb-2"
      apElementDirective
    >
      <svg-icon
        class="ap-w-[20px] ap-h-[20px]"
        [applyClass]="true"
        src="assets/img/newDesign/conversation/magnifier.svg"
        alt="Filters"
      >
      </svg-icon>
      <input
        matInput
        placeholder="Search"
        i18n-placeholder
        autocomplete="off"
        [formControl]="searchControl"
        (keydown.Space)="$event.stopPropagation()"
      />
    </mat-form-field>

    <ap-paginator />
  </div>
</div>
<div
  class="ap-rounded-xl ap-bg-white ap-border ap-border-[#E4E7EC] ap-max-h-[calc(100vh-203px)] ap-overflow-auto custom-conversation-table"
  [ngClass]="{ '!ap-max-h-[calc(100vh-218px)]': caseId }"
>
  <div class="ap-overflow-auto">
    <table mat-table [dataSource]="dataSource" class="ap-w-[100%]">
      <ng-container matColumnDef="from">
        <th
          mat-header-cell
          *matHeaderCellDef
          i18n
          class="ap-sticky ap-top-0 ap-z-10 !ap-text-[#475467]"
        >
          <span
            class="ap-text-[#475467] ap-block ap-font-inter ap-text-xs ap-font-medium ap-leading-none"
          >
            From
          </span>
        </th>
        <td mat-cell *matCellDef="let run">
          <div class="ap-justify-start ap-items-center ap-gap-3 ap-inline-flex">
            <div
              class="ap-w-5 ap-h-5 ap-bg-[#E4E7EC] ap-rounded-lg ap-flex ap-justify-center ap-items-center ap-uppercase ap-text-[#667085] ap-text-sm ap-font-inter ap-font-medium"
            >
              {{ run.from ? run.from.charAt(0) : '' }}
            </div>
            <div
              class="ap-flex-col ap-justify-start ap-items-start ap-inline-flex"
            >
              <div
                class="ap-text-[#101828] ap-text-sm ap-font-medium ap-font-inter"
              >
                {{ run.from.split(' <')[0].trim() }}
              </div>
            </div>
          </div>
        </td></ng-container
      >

      <ng-container matColumnDef="date">
        <th
          mat-header-cell
          *matHeaderCellDef
          i18n
          class="ap-sticky ap-top-0 ap-z-10 !ap-text-[#475467]"
        >
          <span
            class="ap-text-[#475467] ap-block ap-font-inter ap-text-xs ap-font-medium ap-leading-none"
          >
            Date
          </span>
        </th>

        <td mat-cell *matCellDef="let run">
          <div
            class="ap-text-[#475467] ap-text-sm ap-font-normal ap-font-inter"
          >
            {{ run.lastUpdateTimeStamp | date : 'd MMM, HH:mm:ss' }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="subject">
        <th
          mat-header-cell
          *matHeaderCellDef
          i18n
          class="ap-sticky ap-top-0 ap-z-10 !ap-text-[#475467]"
        >
          <span
            class="ap-text-[#475467] ap-block ap-font-inter ap-text-xs ap-font-medium ap-leading-none"
          >
            Subject
          </span>
        </th>

        <td mat-cell *matCellDef="let run">
          <div
            class="ap-text-[#475467] ap-text-sm ap-font-normal ap-font-inter"
          >
            {{ run.subject }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="tags">
        <th
          mat-header-cell
          *matHeaderCellDef
          i18n
          class="ap-sticky ap-top-0 ap-z-10 !ap-text-[#475467]"
        >
          <span
            class="ap-text-[#475467] ap-block ap-font-inter ap-text-xs ap-font-medium ap-leading-none"
          >
            Tags
          </span>
        </th>

        <td mat-cell *matCellDef="let run">
          <div
            class="ap-flex ap-flex-row ap-gap-1 ap-justify-start ap-items-center"
          >
            <ng-container *ngFor="let tag of run.tags; let i = index">
              <span
                *ngIf="i < 2"
                class="ap-rounded-2xl ap-px-2 ap-py-[2px] ap-font-inter ap-text-xs ap-font-medium"
                [ngClass]="{
                  'ap-bg-[#F9F5FF] ap-text-[#6941C6]': i === 0,
                  'ap-bg-[#EFF8FF] ap-text-[#175CD3]': i === 1
                }"
              >
                {{ tag }}
              </span>
            </ng-container>
            <span
              *ngIf="run.tags.length > 2"
              class="ap-font-inter ap-text-xs ap-font-medium ap-text-[#344054]"
            >
              +{{ run.tags.length - 2 }}
            </span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th
          mat-header-cell
          *matHeaderCellDef
          i18n
          class="ap-sticky ap-top-0 ap-z-10 !ap-text-[#475467]"
        >
          <span
            class="ap-text-[#475467] ap-block ap-font-inter ap-text-xs ap-font-medium ap-leading-none"
          >
            Status/Next Action
          </span>
        </th>
        <td mat-cell *matCellDef="let run">
          <div class="ap-py-2 ap-justify-start ap-items-center ap-inline-flex">
            <div
              class="ap-mix-blend-multiply ap-justify-start ap-items-start ap-flex"
            >
              <div
                class="ap-text-xs ap-font-medium ap-font-inter ap-py-[2px] ap-px-2 ap-rounded-2xl ap-flex ap-flex-row ap-justify-center ap-items-center"
                [ngClass]="{
                  'ap-text-[#DC6803]  ap-bg-[#FDF5EC]': run.status === 'PAUSED',
                  'ap-text-[#027A48] ap-bg-[#ECFDF3]':
                    run.status === 'SUCCEEDED',
                  'ap-text-[#D92D20] ap-bg-[#F7E8E8]':
                    run.status === 'FAILED' ||
                    run.status === 'STOPPED' ||
                    run.status === 'INTERNAL_ERROR',
                  'ap-text-[#0158B4] ap-bg-[#bcd8ff]': run.status === 'RUNNING'
                }"
              >
                <div
                  *ngIf="run.status === 'RUNNING'"
                  class="spinner-border loading-spinner-size-and-position !ap-border-primary !ap-border-r-transparent ng-star-inserted"
                  role="status"
                ></div>
                {{
                  run.status === 'PAUSED'
                    ? 'Waiting'
                    : run.status === 'SUCCEEDED'
                    ? 'Complete'
                    : run.status === 'FAILED' ||
                      run.status === 'STOPPED' ||
                      run.status === 'INTERNAL_ERROR'
                    ? 'Reject'
                    : run.status === 'RUNNING'
                    ? 'Running'
                    : run.status
                }}
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="ap-sticky ap-top-0 ap-z-10 !ap-text-center !ap-text-[#475467]"
        ></th>
        <td mat-cell *matCellDef="let run" class="!ap-text-center">
          <button
            mat-icon-button
            (click)="$event.stopImmediatePropagation()"
            [matMenuTriggerFor]="menu"
            *ngIf="run.status !== ExecutionOutputStatus.RUNNING"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" xPosition="before">
            <button
              mat-menu-item
              (click)="retryFlow(run, FlowRetryStrategy.ON_LATEST_VERSION)"
            >
              <div class="ap-flex ap-items-center ap-gap-3">
                <svg-icon
                  class="ap-w-[16px] ap-h-[16px]"
                  [applyClass]="true"
                  src="assets/img/newDesign/extractor/edit.svg"
                >
                </svg-icon>
                <span
                  class="ap-text-[#344054] ap-text-sm ap-font-inter ap-font-medium"
                  i18n
                  >Edit</span
                >
              </div>
            </button>
            <button
              *ngIf="run.status !== ExecutionOutputStatus.SUCCEEDED"
              mat-menu-item
              (click)="retryFlow(run, FlowRetryStrategy.FROM_FAILED_STEP)"
            >
              <div class="ap-flex ap-items-center ap-gap-3">
                <svg-icon
                  class="ap-w-[16px] ap-h-[16px]"
                  [applyClass]="true"
                  src="assets/img/newDesign/extractor/delete.svg"
                >
                </svg-icon>
                <span
                  class="ap-text-[#344054] ap-text-sm ap-font-inter ap-font-medium"
                  i18n
                  >Delete</span
                >
              </div>
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <tr
        class="!ap-bg-white"
        mat-header-row
        *matHeaderRowDef="displayedColumns"
      ></tr>
      <tr
        class="ap-cursor-pointer hover:!ap-bg-hover odd:!ap-bg-[#F9FAFB] even:!ap-bg-white"
        (click)="openInstanceRun(row)"
        mat-row
        [class.ap-hidden]="dataSource.isLoading$ | async"
        *matRowDef="let row; columns: displayedColumns"
      ></tr>
    </table>
  </div>
</div>
