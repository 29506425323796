import { Static, Type } from '@sinclair/typebox'

export const BucketFile = Type.Object({
    id: Type.String(),
    projectId: Type.String(),
    workflowId: Type.String(),
    runId: Type.String(),
    stepId: Type.String(),
    size: Type.Number(),
    data: Type.Unknown(),
    teamId: Type.String(),
})

export type BucketFile = Static<typeof BucketFile>

export const BucketFileUpsert = Type.Object({
    name: Type.String(),
    file: Type.Unknown(),
    type: Type.String(),
    workflowId: Type.String(),
    runId: Type.String(),
    stepId: Type.String(),
    path: Type.Optional(Type.String()),
})

export type BucketFileUpsert = Static<typeof BucketFileUpsert>


export const BucketFileWithUrl = Type.Composite([BucketFile, Type.Object({
    url: Type.String(),
})])

export type BucketFileWithUrl = Static<typeof BucketFileWithUrl>
