export type UpbrainsMapperOutput = {
    event_types: string[]
    integration_name: string
    ticket_or_conversation_id: string
    comment_or_message_id: string
    inbox_or_group_id: string
    channel_type: string
    channel_address: string
    from: { name: string, email: string }[]
    to: { name: string, email: string }[]
    subject: string
    body: string
    attachments: {
        id: string
        integration_name: string
        name: string
        size: number
        content_url: string
        content_type: string
        is_inline: boolean
    }[]
    timestamp: number
    tags: string[]
}

export enum UpBrainsMapperMessageType {
    FRONT = 'FRONT',
    ZENDESK_TICKET = 'ZENDESK_TICKET',
    ZENDESK_COMMENT = 'ZENDESK_COMMENT',
    OUTLOOK = 'OUTLOOK',
    GMAIL = 'GMAIL',
}
