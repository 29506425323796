<div class="ap-flex ap-flex-col ap-w-full">
  <div
    class="ap-flex ap-items-center ap-justify-between ap-cursor-pointer ap-rounded-[6px] ap-h-10 ap-px-2"
    *ngIf="sideMenuItem.children"
    (click)="
      clickHandler($event, sideMenuItem);
      sideMenuItem.children.length
        ? toggleItemExpand('nestedItem')
        : navigateTo(sideMenuItem.route || '/agents')
    "
    [ngClass]="{
      'ap-bg-[#F9FAFB]': (sideMenuItem.children.length && expandedItems['nestedItem']) || isActive,
      'ap-text-[#475467]': isActive,
      'ap-text-[#98A2B3]': !isActive,
    }"
  >
    <div
      class="ap-flex ap-items-center ap-gap-3 ap-font-inter ap-font-medium ap-text-sm ap-transition-all"
    >
      <div class="ap-w-[24px] ap-h-[24px]">
        <ng-container *ngIf="sideMenuItem?.sideMenuIconSrc">
          <svg-icon
            *ngIf="sideMenuItem.sideMenuIconSrc"
            [applyClass]="true"
            [svgStyle]="{ width: '100%', height: '100%' }"
            [src]="sideMenuItem.sideMenuIconSrc"
            [ngClass]="{
              'custom-active-stroke': isActive,
              'custom-deactivate-stroke': !isActive
            }"
          ></svg-icon>
        </ng-container>
      </div>
      <ng-container *ngIf="!isCollapsed && sideMenuItem?.sideMenuItemName"
        ><span class="ap-text-base ap-font-medium">{{
          sideMenuItem.sideMenuItemName
        }}</span></ng-container
      >
    </div>
    <ng-container *ngIf="!isCollapsed && sideMenuItem?.children?.length">
      <svg-icon
        [applyClass]="true"
        [svgStyle]="{ width: '16px', height: '16px' }"
        [ngClass]="{
          'ap-rotate-180': !expandedItems['nestedItem'],
          'ap-transition-transform': true
        }"
        [src]="'assets/img/newDesign/side-menu/arrow-up.svg'"
      ></svg-icon>
    </ng-container>
  </div>

  <ng-container
    *ngIf="
      expandedItems['nestedItem'] &&
      !isCollapsed &&
      sideMenuItem?.children?.length
    "
  >
    <div
      class="!ap-flex !ap-flex-col !ap-mt-2 !ap-pl-[17px] !ap-ml-[26px] !ap-border-l !ap-border-[#E4E7EC] !ap-text-[#98A2B3] ap-text-base !ap-font-medium !ap-h-fit !ap-overflow-hidden"
    >
      <div
        class="ap-font-inter ap-text-sm ap-cursor-pointer ap-my-2"
        *ngFor="let nestedItem of sideMenuItem?.children || []"
        (click)="clickHandler($event, nestedItem); navigateTo(nestedItem.route)"
        [ngClass]="{
          'ap-text-[#475467]': fullPath.includes(nestedItem.route),
          'ap-text-[#98A2B3]': !fullPath.includes(nestedItem.route)
        }"
      >
        {{ nestedItem.title }}
      </div>
    </div>
  </ng-container>
</div>

<!-- [ngClass]="{
  'ap-bg-[#F9FAFB]': sideMenuItem?.sideMenuItemName ? fullPath.includes(sideMenuItem?.sideMenuItemName?.toLocaleLowerCase() ?? '') : false,
}" -->
