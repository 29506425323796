export type ExtractorListResponse = {
    number_of_results: number
    results: ExtractorList[]
    status: number
}

export type ExtractorList = {
    auto_training?: boolean
    display_name?: string
    extraction_schema?: Extractionschema
    extractor_config?: Extractorconfig
    extractor_name?: string
    extractor_status?: string
    id?: string
    is_system_extractor?: boolean
    ai_model_id?: string
    model_name?: string
    model_type?: string
    only_file_processable?: boolean
    service_name?: string
    team_id?: number
    project_id?: string
}

export enum ExtractorActionType {
    EDIT = 'edit',
    VIEW = 'view',
    CREATE = 'create',
    USE = 'use',
}

export type Extractorconfig = {
    pages: string
}

export type Extractionschema = {
    fields: ExtractorField[]
    line_items: ExtractorLineitem[]
    user_instruction: string
}

export type ExtractorLineitem = {
    description: string
    name: string
    type: string
    addTolist?: boolean
}

export type ExtractorField = {
    description?: string
    many?: boolean
    name: string
    type?: string
    addTolist?: boolean
}

export type AttachmentResult = {
    fields: ExtractorField[]
    lineitem: ExtractorLineitem[] | null
}
//Attachment model

export * from './extractor'
export * from './extractor-dto'
