import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CellContextMenuEvent, ColDef } from 'ag-grid-community';
import {
  TableColumns,
  TableConfig,
  TableData,
} from '../../utils/table-component.utils';
import { CustomObjectCellEditor } from '../custom-object-cell-editor/custom-object-cell-editor.component';
import { ProductNumberCellComponent } from '../ProductNumberCell/product-number-cell.component';
import { ColumnNames, ColumnTypes } from '@upbrains/shared';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<T> implements OnInit, OnChanges {
  constructor() {}

  @Input() columns: TableColumns<T>[];
  @Input() data: TableData<T>[];
  @Input() config?: TableConfig<T>;
  @Input() contextmenu: (event: any) => void;
  @Input() cellValueChanged: (event: any) => void;
  @Input() lineNumber: string[] | undefined = [];
  @Output() tableUpdate = new EventEmitter<
    { selectedValue: string; rowIndex: number } | undefined
  >();

  columnDefs: ColDef[] = [];

  rowData = [];

  getNestedValue(obj: any, path: string) {
    return path.split('.').reduce((acc, key) => acc[key], obj);
  }

  ngOnInit() {
    this.updateData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateData();
  }

  updateData() {
    let col: ColDef[] = [];

    this.data.forEach((row: any, i) => {
      row['index'] = this.lineNumber ? this.lineNumber[i] : i + 1;
    });

    col.push({
      width: 70,
      headerName: 'Index',
      field: 'index',
      sortable: true,
      // sort: 'asc', /// TODO: fix this sorting part
      comparator: (valueA, valueB) => Number(valueA) - Number(valueB),
    });

    this.columns.forEach((colItem) => {
      if (
        (colItem.name === ColumnNames.Quantity ||
          colItem.name === ColumnNames.UnitPrice) &&
        colItem.isMatched
      ) {
        col.push({
          headerName: colItem.title ?? (colItem?.name as string),
          field: colItem?.name as string,
          minWidth: colItem?.width,
          editable: true,
          cellRenderer: (params: any) => {
            const value = params.value;
            if (typeof value === 'object' && value !== null) {
              return `
                <div>
                  <div>${value.value}</div>
                  <span
                  class="ap-text-[#DC6803] ap-text-xs ap-font-inter ap-font-medium ap-flex ap-flex-row ap-gap-1 ap-items-center"
                >
                  <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.99989 3.99998V5.99998M5.99989 7.99998H6.00489M5.14489 1.42998L0.909888 8.49998C0.822572 8.65119 0.776372 8.82263 0.775883 8.99724C0.775394 9.17185 0.820634 9.34355 0.907102 9.49524C0.993569 9.64694 1.11825 9.77335 1.26874 9.86191C1.41923 9.95046 1.59029 9.99806 1.76489 9.99998H10.2349C10.4095 9.99806 10.5805 9.95046 10.731 9.86191C10.8815 9.77335 11.0062 9.64694 11.0927 9.49524C11.1791 9.34355 11.2244 9.17185 11.2239 8.99724C11.2234 8.82263 11.1772 8.65119 11.0899 8.49998L6.85489 1.42998C6.76575 1.28303 6.64025 1.16154 6.49049 1.07722C6.34072 0.992904 6.17176 0.948608 5.99989 0.948608C5.82802 0.948608 5.65905 0.992904 5.50929 1.07722C5.35953 1.16154 5.23402 1.28303 5.14489 1.42998Z" stroke="#DC6803" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  ${value.alternative}
                  </span>
                </div>
              `;
            }
            return value;
          },
          autoHeight: true,
          cellEditor: CustomObjectCellEditor,
          cellEditorParams: {
            getValue: (data: any) =>
              typeof data === 'object' ? data.value : data,
          },
          onCellValueChanged: (params: any) => {
            const newValue = params.newValue;
            if (typeof params.oldValue === 'object') {
              params.data[params.column.colId] = newValue;
            } else {
              params.data[params.column.colId] = newValue.value;
            }
            colItem?.onEdit?.(
              params.node.rowIndex,
              params.column.colId,
              typeof params.data[params.column.colId] === 'object'
                ? params.data[params.column.colId].value
                : params.data[params.column.colId]
            );
            this.config?.onEditCell?.(params.data);
          },
          onCellClicked: (params: any) => {
            this.config?.onRowClick?.(params.data);
            this.config?.onClickCell?.(params.data);
          },
        });
      } else if (colItem.type === ColumnTypes.Select) {
        col.push({
          headerName: colItem.title ?? (colItem?.name as string),
          field: colItem?.name as string,
          sortable: true,
          filter: true,
          editable: true,
          minWidth: colItem?.width,
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: (params: any) => {
            const value = this.getNestedValue(
              this.rowData[params.rowIndex],
              colItem.name as string
            );
            return {
              values: value,
            };
          },
        });
      } else if (
        colItem.type === ColumnTypes.Component &&
        colItem.name !== ColumnNames.ProductNumber
      ) {
        col.push({
          headerName: colItem.title ?? (colItem?.name as string),
          field: colItem?.name as string,
          sortable: true,
          cellRenderer: colItem?.component,
          cellRendererParams: colItem?.componentParams,
          autoHeight: true,
          width: colItem?.width,
        });
      } else if (colItem.name === ColumnNames.ProductNumber) {
        const colItemField = colItem?.name as string;
        col.push({
          headerName: colItem.title ?? colItemField,
          field: colItemField,
          sortable: true,
          cellRenderer: ProductNumberCellComponent,
          cellRendererParams: (params: any) => ({
            options: params.data[colItemField]?.list ?? [],
            onEdit: (rowIndex: number, colId: string, value: string) => {},
            selectedValue:
              params.data[colItemField]?.selectedProductNumber ?? '',
            onSelectionChange: (selectedValue: string) => {
              const rowIndex = params.node.rowIndex;
              params.data[colItemField].selectedProductNumber = selectedValue;
              this.tableUpdate.emit({ selectedValue, rowIndex });
            },
          }),
          autoHeight: true,
          width: colItem?.width
            ? colItem.width > 250
              ? 300
              : colItem.width * 2.5
            : 200,
          cellStyle: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          },
        });
      } else {
        col.push({
          headerName: colItem.title ?? (colItem?.name as string),
          field: colItem?.name as string,
          editable: colItem.isEdit ?? false,
          filter: true,
          minWidth: colItem?.width || 120,
          flex: 1,
          cellRenderer: colItem?.render ?? undefined,
          onCellValueChanged: (params: any) => {
            const newValue = params.newValue ?? '';
            const oldValue = params.oldValue ?? '';

            if (newValue !== oldValue) {
              params.data[params.column.colId] = newValue;

              colItem?.onEdit?.(
                params.node.rowIndex,
                params.column.colId,
                newValue
              );
              this.config?.onEditCell?.(params.data);

              params.api.refreshCells({
                rowNodes: [params.node],
                columns: [params.column.colId],
              });
            }
          },
          onCellClicked: (params: any) => {
            this.config?.onRowClick?.(params.data);
            this.config?.onClickCell?.(params.data);
          },
        });
      }
    });

    this.columnDefs = col;

    this.rowData = [...this.data] as any;
  }

  onCellContextMenu(event: CellContextMenuEvent) {
    const mouseEvent = event.event as MouseEvent;

    mouseEvent.preventDefault();
    mouseEvent.stopPropagation();

    this.contextmenu(mouseEvent);
  }
}
