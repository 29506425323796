export type UpbrainsMapperOutput = {
    event_type: string
    integration_name: string
    ticket_or_conversation_id: string
    comment_or_message_id: string
    inbox_or_group_id: string
    channel_type: string
    channel_address: string
    from: { name: string, email: string }
    to: { name: string, email: string }[]
    subject: string
    body: string
    html_body: string
    attachments: {
        id: string
        integration_name: string
        name: string
        size: number
        content_url: string
        content_type: AttachmentType
        is_inline: boolean
    }[]
    timestamp: number
    tags: string[]
}

export enum UpBrainsMapperMessageType {
    FRONT = 'FRONT',
    ZENDESK_TICKET = 'ZENDESK_TICKET',
    ZENDESK_COMMENT = 'ZENDESK_COMMENT',
    OUTLOOK = 'OUTLOOK',
    GMAIL = 'GMAIL',
}

export enum AttachmentType {
    PDF = 'PDF',
    TEXT = 'TEXT',
    DOC = 'DOC',
    IMAGE = 'IMAGE',
    OTHER = 'OTHER',
}

// Front Message Type (Based on https://developers.front.com/reference/get_events)
export type FrontMessage = {
    _links: {
        self: string
    }
    id: string
    type: 'inbound' | 'outbound' | 'comment'
    emitted_at: number
    conversation: {
        _links: {
            self: string
            related: {
                events: string
                followers: string
                messages: string
                comments: string
                inboxes: string
                last_message: string
            }
        }
        id: string
        subject: string
        status: string
        assignee: null | {
            /* assignee details */
        }
        recipient: {
            _links?: {
                related: {
                    contact: string
                }
            }
            name: string
            handle: string
            role: string
        }
        tags: string[]
        created_at: number
        is_private: boolean
    }
    source: {
        _meta: {
            type: string
        }
        data: {
            _links: {
                self: string
                related?: Record<string, string>
            }
            id: string
            name: string
            is_private?: boolean
            is_public?: boolean
            address: string
            type?: string
        }[]
    }
    target: {
        _meta: {
            type: string
        }
        data: {
            _links: {
                self: string
                related: Record<string, string>
            }
            id: string
            type: string
            is_inbound: boolean
            created_at: number
            blurb: string
            body: string
            text: string
            subject: string
            error_type: string | null
            draft_mode: string | null
            metadata: Record<string, unknown>
            author: null
            recipients: {
                _links: {
                    related: {
                        contact: string | null
                    }
                }
                name: string
                handle: string
                role: string
            }[]
            attachments: {
                id: string
                url: string
                filename: string
                content_type: string
                size: number
                metadata: {
                    is_inline: boolean
                }
            }[]
            signature: null
            is_draft: boolean
        }
    }
}

export type OutlookMessageAttachment = {
    '@odata.type': string
    '@odata.mediaContentType': string
    id: string
    name: string
    contentType: string
    size: number
    isInline: boolean
    contentURL?: string //raw file download
    jsonObjectURL?: string //either forwarded emails attachment URL OR base64 download URL
    contentId?: string
    lastModifiedDateTime?: string
    attachmentType: 'file' | 'item' | 'reference'
}

// Outlook Message Type (Based on Microsoft Graph API documentation)
export type OutlookMessage = {
    '@odata.context': string
    '@odata.etag': string
    id: string
    createdDateTime: string
    lastModifiedDateTime: string
    changeKey: string
    categories: string[]
    receivedDateTime: string
    sentDateTime: string
    hasAttachments: boolean
    internetMessageId: string
    subject: string
    bodyPreview: string
    importance: 'low' | 'normal' | 'high'
    parentFolderId: string
    conversationId: string
    conversationIndex: string
    isDeliveryReceiptRequested: boolean | null
    isReadReceiptRequested: boolean
    isRead: boolean
    isDraft: boolean
    webLink: string
    inferenceClassification: string
    body: {
        contentType: 'text' | 'html'
        content: string
    }
    sender: {
        emailAddress: {
            name: string
            address: string
        }
    }
    from: {
        emailAddress: {
            name: string
            address: string
        }
    }
    toRecipients: {
        emailAddress: {
            name: string
            address: string
        }
    }[]
    ccRecipients: {
        emailAddress: {
            name: string
            address: string
        }
    }[]
    bccRecipients: {
        emailAddress: {
            name: string
            address: string
        }
    }[]
    replyTo: {
        emailAddress: {
            name: string
            address: string
        }
    }[]
    flag: {
        flagStatus: 'notFlagged' | 'complete' | 'flagged'
    }
    isSent: boolean
    attachments?: OutlookMessageAttachment[] // Added attachments property
}

// Zendesk Ticket Type (Based on Zendesk API documentation)
export type ZendeskTicket = {
    ticket: {
        id: number
        url: string
        via: {
            source: {
                to: Record<string, unknown>
                rel: string | null
                from: Record<string, unknown>
            }
            channel: string
        }
        tags: string[]
        type: string | null
        due_at: string | null
        fields: {
            id: number
            value: string | number | boolean | null
        }[]
        status: string
        subject: string
        brand_id: number
        group_id: number | null
        priority: string | null
        is_public: boolean
        recipient: string | null
        created_at: string
        encoded_id: string
        problem_id: number | null
        updated_at: string
        assignee_id: number | null
        description: string
        external_id: string | null
        raw_subject: string
        email_cc_ids: number[]
        follower_ids: number[]
        followup_ids: number[]
        requester_id: number
        submitter_id: number
        custom_fields: {
            id: number
            value: string | number | boolean | null
        }[]
        has_incidents: boolean
        forum_topic_id: number | null
        ticket_form_id: number
        organization_id: number
        collaborator_ids: number[]
        custom_status_id: number
        allow_attachments: boolean
        allow_channelback: boolean
        generated_timestamp: number
        satisfaction_rating: {
            score: string
        }
        sharing_agreement_ids: number[]
        from_messaging_channel: boolean
    }
}

// Zendesk Comment Type(Last Comment) (Based on Zendesk API documentation)
export type ZendeskComment = {
    lastComment: {
        id: number
        type: string
        author_id: number
        body: string
        html_body: string
        plain_body: string
        public: boolean
        attachments: any[]
        audit_id: number
        via: {
            channel: string
            source: {
                from: Record<string, unknown>
                to: {
                    name: string
                    address: string
                }
                rel: null
            }
        }
        created_at: string
        metadata: {
            system: {
                client: string
                ip_address: string
                location: string
                latitude: number
                longitude: number
            }
            custom: Record<string, unknown>
        }
    }
    allComments: {
        id: number
        type: string
        author_id: number
        body: string
        html_body: string
        plain_body: string
        public: boolean
        attachments: any[]
        audit_id: number
        via: {
            channel: string
            source: {
                from: Record<string, unknown>
                to: {
                    name: string
                    address: string
                }
                rel: null
            }
        }
        created_at: string
        metadata: {
            system: {
                client: string
                ip_address: string
                location: string
                latitude: number
                longitude: number
            }
            custom: Record<string, unknown>
        }
    }[]
}

// Define the mapping between UpBrainsMapperMessageType and the corresponding message types
type MessageTypeMap = {
    [UpBrainsMapperMessageType.FRONT]: FrontMessage
    [UpBrainsMapperMessageType.OUTLOOK]: OutlookMessage
    [UpBrainsMapperMessageType.ZENDESK_TICKET]: ZendeskTicket
    [UpBrainsMapperMessageType.ZENDESK_COMMENT]: ZendeskComment
}

// Create a generic type that maps UpBrainsMapperMessageType to the corresponding message type
export type MessageType<T extends UpBrainsMapperMessageType> =
    T extends keyof MessageTypeMap ? MessageTypeMap[T] : never
