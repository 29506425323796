import { ICellRendererParams } from 'ag-grid-community';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  templateUrl: './column-description.component.html',
  styleUrls: ['./column-description.component.scss'],
})
export class ColumnDescriptionComponent implements ICellRendererAngularComp {
  public options: string[] = [];
  public label?: string | null = '';
  public params: any = null;
  public model = '';
  @Output() customEvent = new EventEmitter<string>();
  @ViewChild('auto') matAutocomplete?: any;

  getFilteredOptions(options: string[], inputValue: string): any {
    if (!inputValue) return options;
    return this.filter(inputValue, options);
  }

  private filter(value: string, options: string[]): any[] {
    const filterValue = value?.toLowerCase();
    return options.filter((option) =>
      option?.toLowerCase().includes(filterValue)
    );
  }

  handleKeydown(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === 'a') {
      const inputElement = event.target as HTMLInputElement;
      inputElement.select();
      event.preventDefault();
    }
  }

  searchClick(text: string) {
    if (this.label) {
      this.params.searchEvent(this.label, this.params.node.rowIndex);
    } else {
      this.params.searchEvent(text, this.params.node.rowIndex);
    }
  }

  setModel(text: string) {
    this.model = text;
    this.params.onEdit(
      this.params.node.rowIndex,
      this.params.column.colId,
      text
    );
  }

  keypress(event: any): void {
    if (event.key === 'Enter') {
      if (!this.options.some((dr) => dr == this.model)) {
        this.params.onEdit(
          this.params.node.rowIndex,
          this.params.column.colId,
          this.model
        );
      }
    } else {
      this.model = event.target.value;
    }
  }

  // gets called once before the renderer is used
  agInit(params: ICellRendererParams): void {
    if (!params) return;

    this.params = params;
    this.options = params.value.options;

    if (!this.options) {
      this.options = [];
    }

    if (params?.value?.select) {
      this.model = params?.value?.select;
    } else {
      const tempParams = { ...params };
      tempParams.value.select = tempParams.value.options.length
        ? tempParams.value.options[0]
        : '';
      this.model = tempParams.value.select;
      this.params = tempParams;
    }

    this.label = params?.value?.value ?? '';

    setTimeout(() => {
      const find = this.matAutocomplete?.options?._results?.find(
        (dr: any) => dr.value == this.model
      );

      if (find) {
        find._selected = true;
      }
    }, 100);
  }

  refresh(params: ICellRendererParams) {
    return false;
  }
}
