<div class="ap-w-full ap-flex ap-items-center ap-gap-2.5 ap-justify-between">
  <button
    matTooltip="Back"
    matTooltipPosition="below"
    [disabled]="!previous"
    (click)="pageChanged.emit(previous!); setCursor(previous!)"
    class="ap-flex ap-flex-row ap-gap-3 ap-text-sm ap-font-inter ap-font-semibold ap-text-[#344054] ap-border ap-border-[#D0D5DD] ap-bg-white ap-py-2 ap-px-[14px] ap-rounded-lg custom-box-shadow"
    [ngClass]="{ 'ap-text-[#667085]': !previous }"
  >
    <svg-icon
      [svgStyle]="{ width: '20px', height: '20px' }"
      class="ap-flex ap-justify-center ap-items-center"
      src="assets/img/custom/paginator/back.svg"
      [ngClass]="{ 'custom-svg-color': !previous }"
    >
    </svg-icon>
    <div>Previous</div>
  </button>
  <div class="ap-flex ap-flex-row ap-justify-center ap-items-center">
    <div class="ap-typography-caption ap-mr-1" i18n>Page Size:</div>
    <mat-form-field class="ap-max-w-[70px] ap-mr-2" subscriptSizing="dynamic">
      <mat-select [formControl]="pageSizeControl">
        <mat-option *ngFor="let size of pageSizes" [value]="size">
          {{ size }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button
      matTooltip="Next"
      matTooltipPosition="below"
      [disabled]="!next"
      (click)="pageChanged.emit(next!); setCursor(next!)"
      class="ap-flex ap-flex-row ap-gap-3 ap-text-sm ap-font-inter ap-font-semibold ap-text-[#344054] ap-border ap-border-[#D0D5DD] ap-bg-white ap-py-2 ap-px-[14px] ap-rounded-lg custom-box-shadow"
      [ngClass]="{ 'ap-text-[#667085]': !next }"
    >
      <div>Next</div>
      <svg-icon
        [svgStyle]="{ width: '20px', height: '20px' }"
        class="ap-flex ap-justify-center ap-items-center"
        src="assets/img/custom/paginator/next.svg"
        [ngClass]="{ 'custom-svg-color': !previous }"
      >
      </svg-icon>
    </button>
  </div>
</div>
<ng-container *ngIf="pageSizeChanged$ | async"></ng-container>
