<div
  class="ap-flex ap-flex-col ap-w-[306px] ap-h-[274px] ap-bg-[#FCFCFD] ap-border ap-border-[#D6DBF5] ap-rounded-[20px] ap-p-6"
>
  <div
    class="ap-w-full ap-h-10 ap-flex ap-flex-row ap-items-center ap-overflow-hidden"
  >
    <ng-container
      *ngFor="let agentData of template.agentsIcons | slice : 0 : 6"
    >
      <div
        class="ap-h-10 ap-w-10 ap-flex ap-items-center ap-justify-center ap-p-[5px]"
      >
        <img
          [src]="agentData.iconUrl"
          class="ap-w-full ap-h-full ap-object-contain"
        />
      </div>
    </ng-container>
  </div>
  <div
    class="ap-text-xl ap-font-semibold ap-font-inter ap-text-[#475467] ap-mt-6"
  >
    {{ template.name }}
  </div>
  <div
    class="ap-text-sm ap-font-normal ap-font-inter ap-text-[#667085] ap-mt-[10px]"
  >
    {{ template.description }}
  </div>
  <a
    class="ap-cursor-pointer ap-mt-auto ap-no-underline ap-text-lg ap-text-[#175cd3] ap-font-semibold ap-font-inter"
    (click)="handleConfigure()"
  >
    <!-- (buttonClicked)="handleSkip()" -->
    <div
      class="ap-flex ap-items-center loading-template ap-text-lg ap-font-semibold ap-rounded-2xl ap-font-inter"
    >
      Configure
      <svg-icon
        [svgStyle]="{ width: '20px', height: '20px' }"
        class="ap-flex ap-justify-center ap-items-center"
        src="assets/img/newDesign/onboarding/chevron-right.svg"
      >
      </svg-icon>
    </div>
  </a>
</div>
