import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-conversation-skeleton',
  templateUrl: './conversation-skeleton.component.html',
})
export class ConversationSkeletonComponent {
  @Input() stepName: { name: string; displayName: string };
  @Input() fetchLogo!: (stepName: string) => Observable<string | undefined>;
}
