export class TableColumns<T> {
  name?: keyof T;
  type:
    | 'text'
    | 'number'
    | 'date'
    | 'longText'
    | 'btn'
    | 'children'
    | 'select'
    | 'component' = 'text';
  title?: string;
  isSort?: boolean;
  isEdit?: boolean;
  component?: any;
  componentParams?: any;
  onClick?: (item: T) => void;
  onEdit?: (index: number, columnKey: string, value: string) => void;
  children?: TableColumns<T>[];
  width?: number;
  icon?: any;
  render?: (item: T) => any;
}

export class TableData<T> {}

export class TableConfig<T> {
  Pk!: keyof T;
  onRowClick?: (item: T) => void;
  onEditCell?: (item: T) => void;
  onClickCell?: (item: T) => void;
  height?: string;
  constructor(height?: string) {
    this.height = height;
  }
}

export class TableHelper {
  static convertToTableColumns<T>(
    data: T | any,
    component: any,
    sortList: any,
    componentParams: any,
    onEdit?: (index: number, columnKey: string, value: string) => void,
    onClick?: (item: T) => void
  ) {
    const convertType = (type: string) => {
      switch (type) {
        case 'String':
          return 'text';
        case 'Number':
          return 'number';
        case 'Address':
          return 'longText';
        default:
          return 'text';
      }
    };

    if (!data.length) return;

    // Filter the list to include only keys present in the valueObject
    const filteredList = Object.keys(data[0]).filter((item) =>
      Object.prototype.hasOwnProperty.call(sortList || {}, item)
    );

    // Sort the filtered list based on the values in the valueObject
    const sortedList = filteredList.sort((a, b) => {
      return sortList[a] - sortList[b];
    });

    const columns = sortedList.map((key) => {
      if (
        data[0][key] &&
        data[0][key]?.matches &&
        data[0][key]?.matches[0]?.candidates
      ) {
        const ls: any[] = data[0][key]?.matches[0]?.candidates?.map(
          (dr: any) => dr?.matched_value || dr?.[key]
        );
        if (ls.length > 0) {
          return {
            type: 'component',
            name: data[0][key]?.field_name,
            title: data[0][key]?.field_name,
            isEdit: true,
            onEdit: onEdit,
            component: component,
            componentParams: componentParams,
            onClick: onClick,
            width: 500,
          } as TableColumns<T>;
        }
        return {
          type: 'text',
          width: 150,
          name: data[0][key]?.field_name,
          title: data[0][key]?.field_name,
          isEdit: true,
          onEdit: onEdit,
          onClick: onClick,
        } as TableColumns<T>;
      } else {
        return {
          width: 100,
          name: data[0][key]?.field_name,
          title: data[0][key]?.field_name,
          type: convertType(data[0][key].type),
          isEdit: true,
          onEdit: onEdit,
          onClick: onClick,
        } as TableColumns<T>;
      }
    });

    return columns.filter((dr) => dr.name != undefined);
  }
  static convertToTableData<T>(data: T | any) {
    let rowData: any[] = [];
    data?.forEach((item: any) => {
      const k = Object.keys(item).reduce((acc, key) => {
        if (item[key]?.matches?.[0]?.candidates) {
          const options = item[key]?.matches[0]?.candidates.map(
            (dr: any) => dr?.matched_value || dr?.[key]
          );

          const result = item[key]?.matches[0]?.result[0]?.matched_value
            ? item[key].matches[0].result[0].matched_value
            : item[key].matches[0].result[0]?.[key];
          if (result && !options.includes(result)) {
            options.unshift(result);
          }

          return {
            ...acc,
            [item[key].field_name]: {
              value: item[key].content,
              options: [...new Set(options)],
              select: result,
            },
          };
        }

        return {
          ...acc,
          [item[key].field_name]: item[key].content,
        };
      }, {});
      rowData.push(k);
    });

    return rowData;
  }

  static trashIcon(params: any) {
    let onClick: (index: number, data: any) => void = () => {};
    const element = document.createElement('button');
    element.innerHTML = `<button  mat-icon-button="" type="button" class="mat-mdc-tooltip-trigger mdc-icon-button mat-mdc-icon-button mat-warn mat-mdc-button-base" mat-ripple-loader-class-name="mat-mdc-button-ripple" mat-ripple-loader-centered="" ng-reflect-message="Delete Row" ng-reflect-color="warn" ng-reflect-disabled="false" tabindex="0" aria-label="Delete Row"><span class="mat-mdc-button-persistent-ripple mdc-icon-button__ripple"></span><svg-icon _ngcontent-ng-c2498291372="" ng-reflect-src="assets/img/custom/trash.svg" ng-reflect-apply-class="true" class="svgClasses ap-flex ap-items-center ap-justify-center ap-fill-body ap-fill-danger ng-star-inserted"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" _ngcontent-ng-c2498291372="" class="svgClasses ap-flex ap-items-center ap-justify-center ap-fill-body ap-fill-danger" aria-hidden="true"><path d="M261 936q-24.75 0-42.375-17.625T201 876V306h-41v-60h188v-30h264v30h188v60h-41v570q0 24-18 42t-42 18H261Zm438-630H261v570h438V306ZM367 790h60V391h-60v399Zm166 0h60V391h-60v399ZM261 306v570-570Z" _ngcontent-ng-c2498291372=""></path></svg></svg-icon><!--bindings={
  "ng-reflect-ng-if": "true"
}--><!--bindings={}--><span class="mat-mdc-focus-indicator"></span><span class="mat-mdc-button-touch-target"></span><span class="mat-ripple mat-mdc-button-ripple"></span></button><!--container--><!--bindings={
  "ng-reflect-ng-if": "false"
}-->`;

    element.addEventListener('click', () =>
      onClick(params.node.rowIndex, params.data)
    );

    return {
      element,
      setOnClick: (callback: (params: any) => void) => (onClick = callback),
    };
  }
}
