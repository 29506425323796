import { Static, Type } from '@sinclair/typebox'

export const GoogleBucketFileType = Type.Object({
    id: Type.String(),
    projectId: Type.String(),
    data: Type.String(),
    size: Type.Number(),
    workflowId: Type.String(),
    runId: Type.String(),
    stepId: Type.String(),
    teamId: Type.String(),
})

export const GetFilesListType = Type.Array(GoogleBucketFileType)

export type GoogleBucketFileType = Static<typeof GoogleBucketFileType>
export type GetFilesListType = Static<typeof GetFilesListType>
