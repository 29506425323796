<div class="ap-flex ap-justify-start ap-items-start ap-gap-3 ap-mt-[30px]">
  <div class="ap-w-[46px] ap-h-[46px] ap-rounded-2xl ap-bg-[#EEF2FF] ap-p-3">
    <img [src]="fetchLogo(stepName.name) | async" class="ap-w-full ap-h-full" />
  </div>
  <div class="ap-grow ap-flex flex-col gap-3">
    <div class="flex flex-col gap-3">
      <div class="ap-flex ap-justify-start ap-items-center ap-gap-3 ap-mt-1">
        <div
          class="ap-text-slate-800 ap-text-base ap-font-bold ap-font-inter ap-flex ap-flex-row ap-gap-1"
        >
          <div
            class="ap-text-slate-800 ap-text-base ap-font-bold ap-font-inter"
          >
            {{ stepName.displayName }}
          </div>
          <ngx-skeleton-loader [theme]="{ height: '24px', width: '70px' }" />
        </div>
      </div>
      <div class="ap-text-slate-400 ap-text-base">
        <ngx-skeleton-loader
          [theme]="{
            height: '50px',
            width: '470px'
          }"
        />
      </div>
    </div>
  </div>
</div>
