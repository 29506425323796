<div class="ap-mb-24">
  <form [formGroup]="form" (submit)="onNextButtonClick()">
    <div
      class="ap-mx-auto ap-rounded-3xl ap-border ap-border-[#E4E7EC] ap-w-[748px] ap-flex ap-flex-col ap-p-6 custom-insights ap-mt-[72px]"
    >
      <div class="ap-flex ap-flex-row ap-gap-3">
        <div class="ap-w-full">
          <div class="ap-text-sm ap-font-medium ap-mb-[6px]">
            Business Name*
          </div>
          <mat-form-field class="ap-w-full">
            <input
              #businessNameInput
              matInput
              type="text"
              formControlName="businessName"
              placeholder="Business Name"
              class="ap-w-full ap-h-[44px] ap-py-[10px] ap-px-[14px] ap-border ap-border-[#D0D5DD] ap-rounded-lg ap-shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] ap-bg-white ap-font-inter ap-font-normal ap-text-sm focus:ap-border-[#1570EF] focus:ap-outline-none focus:ap-ring-0"
              required
            />
            <mat-error *ngIf="form.controls.businessName.hasError('required')">
              Business name is required
            </mat-error>
            <mat-error *ngIf="form.controls.businessName.hasError('invalidUrl')"
              >Business name is not valid</mat-error
            >
          </mat-form-field>
        </div>

        <div class="ap-w-full">
          <div class="ap-text-sm ap-font-medium ap-mb-[6px]">
            Business Industry*
          </div>
          <mat-form-field appearance="outline" class="ap-w-full">
            <mat-select
              formControlName="selectedIndustry"
              placeholder="Select Industry"
              required
            >
              <mat-option *ngFor="let option of industryList" [value]="option">
                {{ option }}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="form.controls.selectedIndustry.hasError('required')"
            >
              Industry selection is required.
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="ap-mt-3">
        <div class="ap-text-sm ap-font-medium ap-mb-[6px]">
          Business Description*
        </div>
        <mat-form-field appearance="outline" class="ap-w-full">
          <textarea
            matInput
            placeholder="Enter a description..."
            formControlName="description"
            rows="5"
            [style.resize]="'none'"
            class="ap-w-full ap-py-[10px] ap-px-[14px] ap-border ap-border-[#D0D5DD] ap-rounded-lg ap-shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] ap-bg-white ap-font-inter ap-font-normal ap-text-sm focus:ap-border-[#1570EF] focus:ap-outline-none focus:ap-ring-0 ap-mt-2"
            [readonly]="!isManual"
          ></textarea>
          <mat-error *ngIf="form.controls.description.hasError('required')">
            Description is required.
          </mat-error>
        </mat-form-field>
      </div>

      <div class="ap-mt-3">
        <div class="ap-text-sm ap-font-medium">Lines of Business*</div>

        <div class="ap-flex ap-flex-row ap-gap-3 ap-items-center">
          <div
            class="ap-flex ap-flex-row ap-gap-1 ap-max-w-[650px] ap-overflow-hidden ap-h-14 ap-items-center custom-line-items-icon ap-pt-4"
          >
            <div *ngFor="let item of lineItems">
              <div
                class="ap-relative ap-px-6 ap-py-1 ap-bg-[#EFF8FF] ap-text-[#175CD3] ap-text-sm ap-font-medium ap-font-inter ap-rounded-2xl business-item hover-container"
              >
                {{ item }}
                <button
                  type="button"
                  (click)="removeItem(item)"
                  class="icon-button"
                >
                  <svg-icon
                    class="ap-w-[12px] ap-h-[12px] ap-absolute ap-top-1 ap-right-1"
                    [applyClass]="true"
                    src="assets/img/newDesign/onboarding/blue-remove.svg"
                  >
                  </svg-icon>
                </button>
              </div>
            </div>
          </div>

          <!-- Add line input and button -->
          <div *ngIf="showAddLineInput" class="ap-pt-4">
            <input
              #lineInput
              type="text"
              class="ap-font-inter business-item custom-add-input"
              (keyup.enter)="onEnterPressed($event, lineInput)"
              (blur)="toggleAddLineInput(false)"
              (input)="adjustWidth(lineInput)"
              [style.width.px]="inputWidth"
            />
            <!-- (keyup.enter)="addLineItem(lineInput.value); lineInput.value = ''" -->
          </div>
          <ap-icon-button
            [width]="17"
            [height]="17"
            iconUrl="assets/img/newDesign/onboarding/add-business-line.svg"
            tooltipText="Add line"
            i18n-tooltipText
            class="!ap-w-8 !ap-h-8"
            (buttonClicked)="toggleAddLineInput(true)"
          ></ap-icon-button>
        </div>

        <p class="line-items-error ap-font-inter" *ngIf="lineItemsError">
          At least one business is required.
        </p>
      </div>
    </div>
  </form>
  <mat-dialog-actions>
    <div class="ap-w-[230px] ap-mt-7 ap-mx-auto custom-button">
      <ap-button
        actionButton
        btnSize="large"
        i18n
        class="ap-w-[230px] ap-rounded-lg custom-confirm-button"
        [fullWidthOfContainer]="true"
        (buttonClicked)="onNextButtonClick()"
        [loading]="isLoading"
      >
        <div
          class="ap-flex ap-items-center loading-template ap-text-lg ap-font-semibold ap-rounded-2xl ap-font-inter"
        >
          Confirm
        </div>
      </ap-button>
    </div>
  </mat-dialog-actions>
</div>
